import { Card } from "antd"
import React from "react"
import SearchList from "../../../components/SearchList/index"
import { correctStatusEnum, obj2oplist, payStatusEnum, renderEnum, zeroOneColor, examTypeEnum } from "../../../common/consts"
import { questionRender } from "../../../utils/ui"
import { Link } from "react-router-dom"

export default function UserAnswerQuestionList() {
  const tableColumnList = [
    {
      title: "试题ID",
      dataIndex: "questionId", // 它是试题ID，而不是套题ID，所以还不好做一个跳转行为处理
    },
    {
      title: "问题",
      dataIndex: "question",
      width: "20%",
      render: v => {
        return questionRender(v)
      }
    },
    {
      title: "试卷名称",
      dataIndex: "source",
      width: "16%",
    },
    {
      title: "试卷类型",
      dataIndex: "examineType",
      render: v => examTypeEnum[v],
    },
    {
      title: "用户手机号",
      dataIndex: "mobile",
    },
    {
      title: "微信昵称",
      dataIndex: "username",
    },
    {
      title: "付款状态",
      dataIndex: "payStatus",
      render: v => renderEnum(v, payStatusEnum, zeroOneColor),
    },
    {
      title: "批改状态",
      dataIndex: "correctStatus",
      render: v => renderEnum(v, correctStatusEnum, zeroOneColor),
    },
    {
      title: "答题时间",
      dataIndex: "gmtCreate",
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (v) => <Link to={`/userManage/userAnswerQuestion/detail/${v}`}>详情</Link>
    }
  ]
  
  const searchColumns = [
    {
      label: '批改状态',
      key: 'commentState',
      type: 'select',
      oplist: obj2oplist(correctStatusEnum),
    },
    {
      label: "付款状态",
      key: "payStatus",
      type: "select",
      oplist: obj2oplist(payStatusEnum),
    },
    {
      label: "试题ID",
      key: "questionId",
      type: "input",
    },
    {
      label: "手机号",
      key: "mobile",
      type: "input",
    },
    {
      label: "试卷类型",
      key: "examineType",
      type: "select",
      oplist: obj2oplist(examTypeEnum, Number),
    },
    // {
    //   label: "试卷名称",
    //   key: "source",
    //   type: "input",
    // }
  ]

  return (
    <Card>
      <SearchList
        url="/user/question/page"
        searchSchema={searchColumns}
        tableColumns={tableColumnList}
        useCache={true}
      />
    </Card>
  )
}