import { Card, Image, Popconfirm, Button, message } from "antd"
import SearchList from "../../components/SearchList"
import P from "../../components/P"
import { checkPermission } from "../../utils/utils"
import { useState } from "react"
import ModalForm from "../../components/ModalForm"
import { bannerStateEnum, obj2oplist, renderEnum, zeroOneColor, bannerShowState } from "../../common/consts"
import { post, get } from "../../common/request"
import { useGet } from "../../hooks/useGet"

export default function(props) {
  const [showModal, setShowModal] = useState(false)
  const [activeBanner, setActiveBanner] = useState({})
  const [reload, setReload] = useState(false)
  const articleListApiRes = useGet(`/system/article/list?page=1&pageSize=1000`)

  const bannerFormConfig = [
    {
      label: "banner名称",
      key: "name",
      type: "input",
      required: true,
      maxLength: 20,
    },
    {
      label: "图片",
      key: "img",
      type: "upload",
      required: true,
    },
    {
      label: "状态",
      key: "state",
      type: "radio",
      oplist: obj2oplist(bannerStateEnum, v => Number(v)),
      required: true,
      initV: bannerShowState,
    },
    {
      label: "排序值",
      key: "sn",
      type: "numberInput",
      required: true,
      w: "100%",
      precision: 0,
    },
    {
      label: "文章ID",
      key: "articleId",
      type: "select",
      oplist: articleListApiRes?.content || [],
      opk: 'id',
      opv: 'title',
      w: "100%",
      precision: 0,
    }
  ]

  const bannerTableColumns = [
    {
      title: "banner名称",
      dataIndex: "name",
    },
    {
      title: "图片",
      dataIndex: "img",
      render: v => <Image width={100} src={v} />
    },
    {
      title: "状态",
      dataIndex: "state",
      render: v => renderEnum(v, bannerStateEnum, zeroOneColor),
    },
    {
      title: "排序值",
      dataIndex: "sn",
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (v, row) => {
        const modifyHandler = () => {
          setActiveBanner(row)

          setShowModal(true)
        }

        const delHandler = () => {
          return get(`/system/banner/remove/${v}`).then(() => {
            message.success("删除成功")
            setReload(!reload)
          })
        }

        return (
          <div className="alistcontainer">
            {
              checkPermission("sys:banner:remove") ? (
                <Popconfirm title="确认删除吗?" onConfirm={delHandler}>
                  <Button type="link">删除</Button>
                </Popconfirm>
              ) : null
            }
            <P onClick={modifyHandler} code="sys:banner:edit" type="link">
              修改
            </P>
          </div>
        )
      }
    }
  ]

  const newHandler = () => {
    setShowModal(true)
  }

  const newOrModifyHandler = values => {
    const postData = {
      ...(activeBanner || {}),
      ...values
    }
    return post("/system/banner/saveOrUpdate", postData).then(res => {
      setReload(!reload)
      message.success("操作成功")
    })
  }

  return (
    <Card>
      <SearchList
        url="/gk/ops/system/banner/list"
        method="get"
        tableColumns={bannerTableColumns}
        needReload={reload}
      >
        <P onClick={newHandler} code="sys:banner:edit" style={{ marginBottom: 20 }} type="primary">
          新增
        </P>
      </SearchList>

      {/** 新增和修改弹窗 */}
      <ModalForm
        title={activeBanner?.id ? "修改banner" : "新增banner"}
        visible={showModal}
        formList={bannerFormConfig}
        onCancel={() => {
          setShowModal(false)
          setActiveBanner({})
        }}
        onOk={newOrModifyHandler}
        initValue={activeBanner}
        width={700}
      />
    </Card>
  )
}