import { Card, Button } from "antd"
import SearchList from "../../../components/SearchList"
import { ablityIsShowEnum, renderEnum, zeroOneColor } from "../../../common/consts"
import P from "../../../components/P"
import { Link } from "react-router-dom"

export default function AblityTest() {
  const tableColumnList = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "套题名称",
      dataIndex: "title",
    },
    {
      title: "是否显示",
      dataIndex: "isShow",
      render: v => renderEnum(v, ablityIsShowEnum, zeroOneColor),
    },
    {
      title: "答题时间/分钟",
      dataIndex: "mins",
    },
    {
      title: "创建时间",
      dataIndex: "gmtCreate",
    },
    {
      title: "操作",
      dataIndex: "id",
      render: v => {
        return (
          <div>
            <P
              code="sys:ability:edit"
              type="link"
            >
              <Link to={`/ablityTest/list/detail/${v}`}>套题详情</Link>
            </P>
            <Button type="link">
              <Link to={`/ablityTest/list/${v}/finishUserList`}>做题用户</Link>
            </Button>
          </div>
        )
      },
    }
  ]

  return (
    <Card>
      <SearchList
        url="/ability/exercises/page"
        method="get"
        tableColumns={tableColumnList}
      >
        <P
          code="sys:ability:edit"
          type="primary"
          style={{ marginBottom: 15 }}
        >
          <Link to="/ablityTest/list/new">新增套题</Link>
        </P>
      </SearchList>
    </Card>
  )
}