import { Card, Button } from "antd";
import SearchList from "../../../components/SearchList";
import { Link } from "react-router-dom";
import { articleStatusEnum, renderEnum, zeroOneColor } from "../../../common/consts"
import P from "../../../components/P";
import { useGet } from "../../../hooks/useGet";

export default function (props) {
  const enums = useGet("/interview/condition")

  const tableColumnList = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "标题",
      dataIndex: "title",
      width: "35%",
    },
    {
      title: "作者",
      dataIndex: "author",
    },
    {
      title: "类型",
      dataIndex: "typeId",
      render: v => {
        const categoryList = enums?.categories || []
        const hitObj = categoryList.find(obj => obj.id == v)
        return hitObj?.name || "-"
      }
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
    },
    {
      title: "状态",
      dataIndex: "status",
      render: v => renderEnum(v, articleStatusEnum, zeroOneColor),
    },
    {
      title: "操作",
      dataIndex: "id",
      render: v => {
        return (
          <P code="sys:article:edit" type="link">
            <Link to={`/config/article/detail/${v}`}>
              编辑
            </Link>
          </P>
        )
      }
    }
  ]

  const newHandler = () => {
    props.history.push("/config/article/new")
  }

  return (
    <Card>
      <SearchList
        url="/system/article/list"
        method="get"
        tableColumns={tableColumnList}
      >
        <P
          onClick={newHandler}
          code="sys:article:edit"
          style={{ marginBottom: 20 }}
          type="primary"
        >
          新增
        </P>
      </SearchList>
    </Card>
  );
}
