import { Card, Form, message, Row, Col, Button } from "antd"
import { post, get } from "../../../common/request"
import { useGet } from "../../../hooks/useGet"
import FormList from "../../../components/FormItem"
import { recommendedQuestionIsShowEnum, recommendedQuesitonNotShowEnum, obj2oplist } from "../../../common/consts"
import PickQuestionList from "../../AbilityTest/form/comp/pickQuestionList"
import { useState, useEffect } from "react"

export default function RecommendedQuestionForm(props) {
  const id = props.match?.params?.id
  const [formInstance] = Form.useForm()
  const enums = useGet("/interview/condition")

  const [examDetail, setExamDetail] = useState({})
  const [loading, setLoading] = useState(false)

  const labelSpan = 2

  const returnYears = () => {
    const yearList = enums?.years || []
    return yearList.map(year => ({
      name: year,
      value: year,
    }))
  }

  const returnCardTitle = () => {
    if (id) {
      return "编辑必练题目试卷"
    }
    return "新增必练题目试卷"
  }

  const width = 600
  const formListConfig = [
    {
      label: "标题",
      key: "title",
      type: "input",
      required: true,
      w: width,
    },
    {
      label: "年份",
      key: "year",
      type: "select",
      required: true,
      oplist: returnYears(),
      w: width,
    },
    {
      label: "试题类型",
      key: "type",
      type: "select",
      oplist: enums?.categories || [],
      opk: "id",
      opv: "name",
      w: width,
      required: true,
    },
    {
      label: "是否显示",
      key: "isShow",
      type: "radio",
      oplist: obj2oplist(recommendedQuestionIsShowEnum, Number),
      initV: recommendedQuesitonNotShowEnum,
      required: true,
    },
    {
      label: "已选试题",
      key: "questionList",
      type: "custom",
      render: v => (
        <PickQuestionList
          questionDetailList={examDetail?.questions || []}
          updateQuestionDetailList={newArr => {
            const newObj = {
              ...examDetail,
              questions: newArr,
            }
            setExamDetail(newObj)
          }}
        />
      ),
      required: true,
    }
  ]

  const handleSave = async () => {
    const values = await formInstance.validateFields()
    const postData = {
      ...examDetail,
      ...values,
    }
    return post("/interview/exercises/saveOrUpdate", postData).then(res => {
      message.success("操作成功")
      props.history.goBack()
    })
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      get(`/interview/exercises/details/${id}`).then(res => {
        setExamDetail(res)
        formInstance.setFieldsValue(res)
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [id])

  return (
    <Card loading={loading} title={returnCardTitle()}>
      <Form form={formInstance} labelCol={{ span: labelSpan }} wrapperCol={{ span: 24 - labelSpan }}>
        <FormList
          span={24}
          list={formListConfig}
        />
      </Form>
      <Row>
        <Col offset={labelSpan}>
          <Button onClick={handleSave} type="primary">保存</Button>
        </Col>
      </Row>
    </Card>
  )
}