import React from "react";
import { connect } from "react-redux";
import { checkPermission, validateUtil } from "../../utils/utils";
import { table_grade, table_shifan } from "./editorTable";

import {
  update_question_displaytitle,
  update_question_suitCrowds,
  update_question_source,
  update_question_joinmaterial,
  add_question_question,
  remove_question_question,
  update_question_solution,
  update_question_year,
  update_question_examineType,
  update_question_area,
  update_question_quesType,
  update_question_description,
  updateQuestionVideo,
  updateQuestion,
  fetchInterview,
  update_question_quesCate,
  update_question_tag,
  update_school,
  update_recruit_type,
  fetchEmpty,
  fetchInterviewCondition,
  setKeyValue,
  changeCommon,
} from "./actions";
import Editor from "../../components/Editor/index";
import { Cascader, Button, Card, Select, message, Input } from "antd";
import { examineType_real, examineType_flexable } from "../../common/consts";

const { Option } = Select;

class InterviewInfo extends React.Component {
  /* constructor(props) {
        super(props);
        this.state = {};
    } */
  isFlexableScene = false;

  componentWillMount() {
    this.isFlexableScene = window.location.href.includes("isFlexableScene=1");
    if (this.props.match.params.id) {
      this.props.dispatch(fetchInterviewCondition());
      this.props.dispatch(fetchInterview(this.props.match.params.id));
    } else {
      this.props.dispatch(fetchInterviewCondition());
      this.props.dispatch(fetchEmpty());
    }
    this.setState({
      type: [],
    });
  }

  selectRecruitType = async (value) => {
    await this.props.dispatch(update_recruit_type(value));
    /* await this.props.recruitTypeSchool.map(item => {
            item.id === Number(value) && this.props.dispatch(changeCommon({ key: 'schools', val: item.subset }))
        }); */
    await this.props.dispatch(update_school(""));
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id && !nextProps.match.params.id) {
      this.props.dispatch(fetchEmpty());
    } else if (!this.props.match.params.id && nextProps.match.params.id) {
      this.props.dispatch(fetchInterview(nextProps.match.params.id));
    }
  }

  uploadFile = () => {
    let file = this.refs.upload.files[0];
    if (file) {
      this.props.dispatch(updateQuestionVideo(file, this.props.info));
    } else {
      message.warning("请上传文件");
    }
  };

  modifyQuestion = async () => {
    let verifyObj = this.props.info;
    // let blackList = ['title', 'type', 'school', 'questionTag', 'year', 'questions'];
    let blackList = ["title", "questionTag", "questions"];
    // let tips = { title: '面试标题', type: '多元升学类型', school: '院校', questionTag: '是否真题', year: '年份', questions: '问题' };
    let tips = {
      title: "面试标题",
      questionTag: "是否真题",
      questions: "问题",
    };
    if (validateUtil(verifyObj, blackList, tips)) return;
    if (this.props.info.questions.length <= 0) {
      message.warning("最少需要填写一个问题");
      return;
    }
    for (let i in this.props.info.questions) {
      let q = this.props.info.questions[i];
      /* if (!q.categoryId) {
                message.warning('面试题型不能为空');
                return;
            } */
      if (q.question.trim().length === 0) {
        message.warning("问题描述不能为空");
        return;
      }
    }
    const infoData = { ...this.props.info };
    // if (this.isFlexableScene) {
    //   infoData.examineType = examineType_flexable;
    // } else {
    //   infoData.examineType = examineType_real;
    // }
    await this.props.dispatch(updateQuestion(infoData));
    let backUrl;
    if (this.isFlexableScene) {
      backUrl = "/flexibleInterview";
    } else {
      backUrl = "/interviewList";
    }
    this.props.history.push(backUrl);
  };

  /** 给某一个问题设置题型 */
  choseCategory = (value, index) => {
    this.props.dispatch(update_question_quesCate(value, index));
  };

  /** 返回标签的焦点值 */
  generateLabelDefaultValue = () => {
    let info = this.props.info || {};
    let result = "";
    if (info.questionTag) {
      result = info.questionTag.toString();
    } else if (this.props.tags && this.props.tags.length) {
      result = this.findSinture().value + "";
      this.props.dispatch(update_question_tag(result));
    } else {
      result = "-1";
    }
    return result;
  };

  /** bad design: 寻找焦点原创 */
  findSinture = () => {
    return this.props.tags.find((obj) => obj.desc.includes("焦点"));
  };

  sourceHandler = (arr) => {
    if (!arr.length) return [];
    if (arr[0].name == "全部") return arr;
    let defaultItem = { id: "", name: "全部", subset: [] };
    arr.map((item) => {
      if (item.subset && item.subset.length) {
        this.sourceHandler(item.subset);
      }
    });
    arr.unshift(defaultItem);
    return arr;
  };

  getParentId = (id) => {
    let { resource } = this.props;
    let pid = "";
    resource &&
      resource.map((item) => {
        if (id == item.id) {
          return (pid = id);
        }
        item.subset.map((i) => {
          if (id == i.id) {
            return (pid = item.id);
          }
        });
      });
    return pid;
  };

  render() {
    const { info } = this.props;
    let resource = this.props.categories;

    /* info.questions && info.questions.map((item, index) => {
            item.categoryId && (item.type == null || item.type == undefined) && resource.map((res, resIndex) => {
                if (res.id == item.categoryId) {
                    this.props.dispatch(update_question_quesType(resIndex, index));
                    return;
                }
                res.subset.map(type => {
                    if (type.id == item.categoryId) {
                        this.props.dispatch(update_question_quesType(resIndex, index));
                        return;
                    }
                })
            })
        }) */

    const onSearchSchool = (value) => {
      // console.log('search:', value);
    };

    const filterOptionSchool = (input, option) => {
      // console.log('option', option);
      // console.log('input', input);
      // console.log((option?.label ?? '').toLowerCase().includes(input.toLowerCase()));
      return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    };

    return (
      <div>
        <h4 style={{ margin: "0 0 0px" }}>
          {this.props.match.params.id ? "面试详情" : "添加面试题"}
        </h4>
        {this.props.isFetching && <h5>加载中……</h5>}
        {!this.props.isFetching && (
          <div className="commonFlexLR">
            <div className="commonLeft">
              <div className="commonSearchBox">
                <div className="itemSearchBox">
                  <label>　　　　标题</label>
                  <Input
                    style={{ width: 708 }}
                    value={info.title}
                    onChange={(e) =>
                      this.props.dispatch(
                        update_question_displaytitle(e.target.value)
                      )
                    }
                  />
                </div>
                {!this.isFlexableScene ? (
                  <div>
                    <label>多元升学类型</label>
                    <Select
                      allowClear={true}
                      value={info.type}
                      className="commonW150"
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => {
                        this.selectRecruitType(value);
                      }}
                    >
                      {this.props.recruitTypeSchool.map((item, idx) => {
                        return (
                          <Option key={idx} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                ) : null}

                {!this.isFlexableScene ? (
                  <div>
                    <label>　　　　院校</label>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(value) =>
                        this.props.dispatch(update_school(value))
                      }
                      onSearch={onSearchSchool}
                      filterOption={filterOptionSchool}
                      options={this.props.schools}
                      allowClear={true}
                      disabled={this.props.schools.length <= 0 && !info.school}
                      value={info.school}
                      className="commonW150"
                      dropdownMatchSelectWidth={false}
                    />
                  </div>
                ) : null}

                <div className="itemSearchBox">
                  <label>　　是否真题</label>
                  <Select
                    allowClear={true}
                    style={{ width: 150 }}
                    value={info.questionTag}
                    dropdownMatchSelectWidth={false}
                    onChange={(value) =>
                      this.props.dispatch(update_question_tag(value))
                    }
                  >
                    {this.props.tags &&
                      this.props.tags.map((category, idx) => {
                        return (
                          <Option key={idx} value={category.value}>
                            {category.desc}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="itemSearchBox">
                  <label>　　　　年份</label>
                  <Select
                    allowClear={true}
                    style={{ width: 150 }}
                    value={info.year}
                    dropdownMatchSelectWidth={false}
                    onChange={(value) =>
                      this.props.dispatch(update_question_year(value))
                    }
                  >
                    {this.props.years &&
                      this.props.years.map((year, idx) => {
                        return (
                          <Option key={idx} value={year + ""}>
                            {year}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="itemSearchBox">
                  <label>　　　　试卷类型</label>
                  <Select
                    allowClear={true}
                    style={{ width: 150 }}
                    value={info.examineType}
                    dropdownMatchSelectWidth={false}
                    onChange={(value) =>
                      this.props.dispatch(update_question_examineType(value))
                    }
                  >
                    <Option key={0} value={0}>
                      真题
                    </Option>
                    <Option key={1} value={1}>
                      必练题目
                    </Option>
                    <Option key={2} value={2}>
                      能力测试
                    </Option>
                  </Select>
                </div>
              </div>

              {info.questions.map((item, index) => {
                return (
                  <Card
                    title={"问题" + (index + 1)}
                    key={this.props.qId + "" + index}
                    size="small"
                  >
                    <div>
                      <label>面试题型</label>
                      <Select
                        allowClear={true}
                        style={{ width: "200px" }}
                        value={item.categoryId}
                        dropdownMatchSelectWidth={false}
                        onChange={(value) => this.choseCategory(value, index)}
                      >
                        {this.props.categories &&
                          this.props.categories.map((item, idx) => {
                            return (
                              <Option key={idx} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                    <br />
                    <div>
                      <div>问题描述</div>
                      <Editor
                        content={item.question}
                        onChange={(value) =>
                          this.props.dispatch(
                            update_question_description(value, index)
                          )
                        }
                      />
                    </div>
                    <br />
                    <div>
                      <div>问题材料</div>
                      <Editor
                        content={item.material}
                        onChange={(value) =>
                          this.props.dispatch(
                            update_question_joinmaterial(value, index)
                          )
                        }
                      />
                    </div>
                    <br />
                    <div>
                      <div>参考解析</div>
                      <Editor
                        content={item.solution}
                        onChange={(value) =>
                          this.props.dispatch(
                            update_question_solution(value, index)
                          )
                        }
                      />
                    </div>
                    <br />
                    <Button
                      danger
                      onClick={() =>
                        this.props.dispatch(remove_question_question(index))
                      }
                    >
                      删除问题
                    </Button>
                  </Card>
                );
              })}
              {
                <Button
                  type="primary"
                  onClick={() => this.props.dispatch(add_question_question())}
                >
                  添加一个新问题
                </Button>
              }
            </div>

            <div className="commonsubmit">
              {checkPermission("sys:view:edit") && (
                <div>
                  {/* <Card title="视频" size="small">
                                    {
                                        this.props.isVideoUpdating &&
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: this.props.progress + "" }}></div>
                                        </div>
                                    }
                                    {this.props.info.videoUrl && <video style={{ width: "100%" }} controls><source src={this.props.info.videoUrl} /></video>}
                                    <input type="file" ref="upload" style={{ margin: '0 0 20px 0' }} />
                                    <Button type="primary" onClick={this.uploadFile} disabled={this.props.isVideoUpdating}>
                                        上传
                                    </Button>
                                </Card> */}
                  <Card
                    title={this.props.match.params.id ? "更新" : "保存"}
                    size="small"
                  >
                    <Button
                      type="primary"
                      onClick={this.modifyQuestion}
                      disabled={this.props.isUpdating}
                    >
                      {this.props.match.params.id ? "更新" : "保存"}
                    </Button>
                  </Card>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStatetoProps(state) {
  const {
    isFetching,
    isUpdating,
    isUpdated,
    isVideoUpdating,
    info,
    progress,
    qId,
    mId,
  } = state.interviewInfo;

  const { resource } = state.interviewCategory;

  const { years } = state.questions;

  const { areas } = state.interviewList;

  const { tags } = state.essayCategory;

  return {
    progress,
    areas,
    years,
    resource,
    isFetching,
    isUpdating,
    isUpdated,
    isVideoUpdating,
    info,
    qId,
    mId,
    tags,
    ...state.interviewInfo,
  };
}

export default connect(mapStatetoProps)(InterviewInfo);
