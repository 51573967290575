import { Upload, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compression } from "./compression";
import { get } from "../../../../common/request";
import OSS from "ali-oss";

// 从链接中去掉url(
const deleteUrl = url => {
  if (url.includes("url(")) {
    // 去除url
    const u2 = url.replaceAll("url(", "")
    const u3 = u2.replaceAll(");", "")
    const u4 = u3.replaceAll(")", "")
    return u4
  } else {
    return url
  }
}

// 由于支持多张图片，所以显示值是一个数组，所以如果接收到的值不是数组的话，这里做一个转化
const toArray = (value, needAddUrl) => {
  if (Object.prototype.toString.call(value) === "[object Array]") {
    if (needAddUrl) {
      // 表示是需要给图片链接拼url的模式
      const newValueArr = value.map(url => {
        return deleteUrl(url)
      })
      return newValueArr
    }
    return value;
  }
  if (!value) {
    return [];
  }

  return [deleteUrl(value)];
};
//判断一条链接是否是视频
const isVideo = (url) => {
  const videoReg = /mp4|avi|rmvb|rm|flv|3gp|mkv|mov/i;
  return videoReg.test(url);
};
// 转成Upload所能接受的参数
const toFileList = (arr) => {
  return arr.map((url) => ({
    url: isVideo(url)
      ? `${url}?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast`
      : url,
    uid: url,
  }));
};

const OssUpload = ({
  onChange,
  value,
  max,
  disabled,
  uploadDir,
  accept,
  needEmit,
  maxSize = 5,
  isUrlMode,
}) => {
  const [imageUrls, setImageUrls] = useState(toArray(value, isUrlMode)); // 上传之后的图片链接
  const [previewImage, setPreviewImage] = useState("");

  const [previewVisible, setPreviewVisible] = useState(false);

  // LOG: 这里是为了实现回显这样处理的，同时又为了避免出现循环effect依赖。bad design，可优化
  // 设计上存在问题，为了搭配Form.Item进行使用所存在的问题
  useEffect(() => {
    if (imageUrls.length) {
      return;
    }
    setImageUrls(toArray(value, isUrlMode));
  }, [value]);

  const onChangeWrapper = (newImgArr) => {
    let emitValue;
    if (max <= 1) {
      // 单选模式，传给父组件一个字符串
      emitValue = newImgArr[0];
      if (isUrlMode && emitValue) {
        emitValue = "url(" + emitValue + ")";
      }
    } else {
      // 多选，传给父组件一个数组
      emitValue = newImgArr;
      if (isUrlMode && emitValue.length) {
        emitValue = emitValue.map(url => "url(" + url + ")")
      }
    }
    onChange(emitValue);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">选择文件</div>
    </div>
  );

  const beforeUpload = async (originFile) => {
    let { name, type } = originFile;
    let resultObj = { file: originFile };

    if (type.includes("image/") && !type.includes("gif")) {
      // 是静图才去进行压缩
      resultObj = await compression(originFile);
    }

    const file = resultObj.file; // 压缩后的对象

    if (file.size > 1024 * 1024 * maxSize) {
      message.error(`文件大小不能超过${maxSize}M`);
      return false;
    }

    const closeCb = message.loading("上传中", 0);
    get("/gk/ops/sts/getToken?type=oss").then(res => {
      const result = res || {}
      let client = new OSS({
        endpoint: 'https://oss-cn-hangzhou.aliyuncs.com',
        stsToken: result.securityToken,
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        cname: false,
        bucket: 'jd-questions'
      });

      name = Date.now() + "_" + name

      return client.multipartUpload(name, file).then((result) => {
        let res = result.res;
        if (res.status == 200) {
          message.success('上传成功');
          let url = res.requestUrls[0].split('?')[0];
          const newImgList = [...imageUrls];
          newImgList.push(url);
          onChangeWrapper(newImgList);
          setImageUrls(newImgList);
        }
      }).catch(error => {
        message.error('上传失败');
        console.log(error)
      })
    }).finally(() => {
      closeCb()
    })
    return false; // 不调用默认的上传方法
  };

  // 删除图片的时候触发
  const delImgRender = (uploadFileObj) => {
    //如果是视频，需要把?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast后缀去掉
    if (isVideo(uploadFileObj.url)) {
      uploadFileObj.url = uploadFileObj.url.split(
        "?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast"
      )[0];
    }
    const newImgUrls = imageUrls.filter((url) => {
      return url !== uploadFileObj.url;
    });
    onChangeWrapper(newImgUrls);
    setImageUrls(newImgUrls);
  };

  // 判断是否需要禁止选择
  const checkNeedDisabled = () => {
    return imageUrls.length >= max;
  };
  //判断previewImage是否有?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast后缀
  const isVideo = (previewImage) => {
    return previewImage.includes(
      "?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast"
    );
  };
  //剪切字符串?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast
  const videoUrl = (previewImage) => {
    return previewImage.split(
      "?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast"
    )[0];
  };
  return (
    <>
      <Upload
        accept={accept}
        disabled={disabled}
        listType="picture-card"
        fileList={toFileList(imageUrls)}
        beforeUpload={beforeUpload}
        onRemove={delImgRender}
        maxCount={max}
        onPreview={(file) => {
          setPreviewImage(file.url);
          setPreviewVisible(true);
        }}
      >
        {checkNeedDisabled() ? null : uploadButton}
      </Upload>

      <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        {isVideo(previewImage) ? (
          <video
            style={{ width: "100%" }}
            controls
            autoplay
            name="media"
            src={videoUrl(previewImage)}
          ></video>
        ) : (
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        )}
      </Modal>
    </>
  );
};

OssUpload.propTypes = {
  onChange: PropTypes.func, // 文件变化
  value: PropTypes.any, // 初始值
  max: PropTypes.any, // 上传照片的最大值
  disabled: PropTypes.any, // 是否禁用
  maxSize: PropTypes.number, // 上传图片的最大mb
  uploadDir: PropTypes.string, // 上传目录
  accept: PropTypes.string, // 上传文件的类型
  // needEmit: PropTypes.func, // 是否需要回调
  isUrlMode: PropTypes.bool, // 是否需要拼接URL，主要适用于活动页配置功能中配置背景图片
};

export default OssUpload;
