import { Card } from "antd"
import SearchList from "../../../components/SearchList"
import { recommendedQuestionIsShowEnum, renderEnum, zeroOneColor } from "../../../common/consts"
import P from "../../../components/P"
import { Link } from "react-router-dom"
import { useGet } from "../../../hooks/useGet"

export default function(props) {
  const enums = useGet("/interview/condition")

  const uiSchema = [
    {
      title: "试卷ID",
      dataIndex: "id",
    },
    {
      title: "标题",
      dataIndex: "title",
    },
    {
      title: "试题数量",
      dataIndex: "totalQuestion",
    },
    {
      title: "类型",
      dataIndex: "type",
      render: v => {
        const categoryList = enums?.categories || []
        const hitObj = categoryList.find(obj => obj.id == v)
        return hitObj?.name || "-"
      }
    },
    {
      title: "年份",
      dataIndex: "year",
    },
    {
      title: "是否显示",
      dataIndex: "isShow",
      render: v => renderEnum(v, recommendedQuestionIsShowEnum, zeroOneColor),
    },
    {
      title: "编辑",
      dataIndex: "id",
      render: v => {
        return (
          <P type="link" code="exercise:question:edit">
            <Link to={`/recommendedQuestion/detail/${v}`}>编辑</Link>
          </P>
        )
      }
    }
  ]

  return (
    <Card>
      <SearchList
        url="/interview/exercises/page"
        method="get"
        tableColumns={uiSchema}
        useCache={true}
      >
        <P style={{ marginBottom: 15 }} code="exercise:question:edit" type="primary">
          <Link to="/recommendedQuestion/new">新增试卷</Link>
        </P>
      </SearchList>
    </Card>
  )
}