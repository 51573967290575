import UserAbilityReport from "../../../components/UserAbilityReport/index"

export default function UserReport(props) {
  const userId = props.match?.params?.userId

  return (
    <UserAbilityReport
      userId={userId}
      successCb={() => {
        props.history.goBack()
      }}
    />
  )
}

// FIXME: del
// import { Card, Row, Col, Form, message } from "antd"
// import { get, post } from "../../../common/request"
// import { useState, useEffect } from "react"
// import FormList from "../../../components/FormItem"
// import P from "../../../components/P"
// import UseTempLateList from "../../../components/UseTempLateList"

// export default function UserReport(props) {
//   const userId = props.match?.params?.userId

//   const [formInstance] = Form.useForm()

//   const [reportDetail, setReportDetail] = useState({})
//   const [loadingReportDetail, setLoadingReportDetail] = useState(false)

//   const formListConfig = [
//     {
//       label: "报告内容",
//       key: "reportContent",
//       type: "richtext",
//       required: true,
//       height: 450,
//     }
//   ]

//   const saveHandler = async () => {
//     const formValues = await formInstance.validateFields()
//     const postData = {
//       ...(reportDetail || {}),
//       ...formValues,
//       userId,
//     }
//     return post("/ability/exercises/report/edit", postData).then(() => {
//       message.success("操作成功")
//       props.history.goBack()
//     })
//   }

//   const returnCatdTitle = () => {
//     const styleObj = {
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "center",
//     }
//     return (
//       <div style={styleObj}>
//         <span>测评报告内容</span>
//         <UseTempLateList
//           onChange={(_, option) => {
//             formInstance.setFieldValue("reportContent", option?.content)
//           }}
//         />
//       </div>
//     )
//   }

//   useEffect(() => {
//     if (!userId) {
//       return
//     }
//     setLoadingReportDetail(true)
//     get(`/ability/exercises/report?userId=${userId}`).then(res => {
//       setReportDetail(res || {})
//       formInstance.setFieldsValue(res)
//     }).finally(() => {
//       setLoadingReportDetail(false)
//     })
//   }, [userId])

//   const labelSpan = 2
//   return (
//     <Card title={returnCatdTitle()} loading={loadingReportDetail}>
//       <Form form={formInstance} labelCol={{ span: labelSpan }} wrapperCol={{ span: 24 - labelSpan }}>
//         <FormList
//           span={24}
//           list={formListConfig}
//         />
//       </Form>
//       <Row>
//         <Col offset={labelSpan}>
//           <P code="ability:report:edit" style={{ marginTop: 20 }} onClick={saveHandler} type="primary">提交</P>
//         </Col>
//       </Row>
//     </Card>
//   )
// }