import apiRequest from '../../common/request';
import { message } from 'antd';
import { OSS_PUB_GK_COURSE, OSS_PUB_GK_NEWS } from '../../common/consts';
import OSS from 'ali-oss';

const PREFIX = "VIDEO_PICKER";

export const VIDEO_PICKER_RESET = PREFIX + "VIDEO_PICKER_RESET";
export const VIDEO_PICKER_REQUEST_VIDEOS = PREFIX + "VIDEO_PICKER_REQUEST_VIDEOS";
export const VIDEO_PICKER_RECEIVE_VIDEOS = PREFIX + "VIDEO_PICKER_RECEIVE_VIDEOS";
export const VIDEO_PICKER_LAST_PAGE = PREFIX + "VIDEO_PICKER_LAST_PAGE";
export const VIDEO_PICKER_NEXT_PAGE = PREFIX + "VIDEO_PICKER_NEXT_PAGE";
export const VIDEO_PICKER_RESET_VIDEO_MARKERS = PREFIX + "VIDEO_PICKER_RESET_VIDEO_MARKERS";
export const VIDEO_PICKER_RECEIVE_CONTENT_OF_VIDEO_DIRECTORY = PREFIX + "VIDEO_PICKER_RECEIVE_CONTENT_OF_VIDEO_DIRECTORY";
export const VIDEO_PICKER_UPLOAD_FINISH = PREFIX + "VIDEO_PICKER_UPLOAD_FINISH";
export const VIDEO_PICKER_START_UPLOAD = PREFIX + "VIDEO_PICKER_START_UPLOAD";

export function video_picker_reset() {
    return {
        type:VIDEO_PICKER_RESET
    }
}

function request_videos() {
    return {
        type: VIDEO_PICKER_REQUEST_VIDEOS
    }
}

function receive_videos(data, prefix="", query="") {
    query = query.trim();
    return {
        type: VIDEO_PICKER_RECEIVE_VIDEOS,
        videos: data.content.filter(i=>i.name.indexOf(query)!=-1),
        marker: data.marker,
        prefixes: prefix?data.prefixes.filter(i=>i.split(prefix)[1].indexOf(query)!=-1):data.prefixes.filter(i=>i.indexOf(query)!=-1),
        receivedAt: Date.now()
    }
}

function video_picker_last_page_success(marker) {
    return {
        type: VIDEO_PICKER_LAST_PAGE,
        marker
    }
}

function video_picker_next_page_success(marker) {
    return {
        type: VIDEO_PICKER_NEXT_PAGE,
        marker
    }
}

function video_picker_start_upload() {
    return {
        type: VIDEO_PICKER_START_UPLOAD,
    }
}

function video_picker_upload_finish(url) {
    return {
        type: VIDEO_PICKER_UPLOAD_FINISH,
        url
    }
}
function receive_content_of_video_directory(prefix) {
    return {
        type: VIDEO_PICKER_RECEIVE_CONTENT_OF_VIDEO_DIRECTORY,
        prefix
    }
}

export function fetch_content_of_video_directory(prefix, pubName, query) {
    return dispatch => {
        dispatch(fetchVideos({ prefix }, pubName, query));
        dispatch(receive_content_of_video_directory(prefix));
        dispatch(reset_video_markers());
    }
}

export function video_picker_last_page(marker, prefix, pubName, query) {
    return dispatch => {
        dispatch(fetchVideos({ marker, prefix }, pubName, query));
        dispatch(video_picker_last_page_success(marker));
    }
}

export function video_picker_next_page(marker, prefix, pubName, query) {
    return dispatch => {
        dispatch(fetchVideos({ marker, prefix }, pubName, query));
        dispatch(video_picker_next_page_success(marker));
    }
}

export function reset_video_markers() {
    return {
        type: VIDEO_PICKER_RESET_VIDEO_MARKERS
    }
}

/**
 *
 * @param {Obj} data : 传给后端的数据
 * @param {String} pubName : 仓库名称
 */
export function fetchVideos(data = {}, pubName, query = "") {
    query = query.trim()
    let requestData = {
        pageSize: query?200:20,
        ...data,
        delimiter: '/'
    };

    // let requestData = {
    //     prefix: 'ques'
    // };
    let config = {
        method: 'POST',
        body: JSON.stringify(requestData),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    let url = '';
    if (pubName === OSS_PUB_GK_COURSE) {
        url = `/gk/ops/media/course/page`;
    } else if (pubName === OSS_PUB_GK_NEWS) {
        url = `/gk/ops/media/tv/page`;
    }
    return dispatch => {
        dispatch(request_videos());
        return apiRequest(url, config)
          .then(json => {          
            dispatch(receive_videos(json.obj, data.prefix||'', query))
          })
          .catch(error => console.log(error));
    }
}


/** 确定是哪个bucket */
const makeSureBucket = isPublicPub => {
    let bucket = 'gk-news';
    if (isPublicPub) {
        bucket = 'gk-resource';             // 这个是视频的私有库
    } else {
        bucket = 'gk-news';                 // 这个是视频的公开库
    }
    return bucket;
}

/** 利用OSS所返回的链接访问不了资源 */
const getCleanUrl = requestUrl => {
    try {
        requestUrl = requestUrl.replace('http://', 'https://')
        let endIndex = requestUrl.indexOf('?');
        if (endIndex !== -1) {
            return requestUrl.substr(0, endIndex);
        } else {
            return requestUrl;
        }
    } catch(err) {
        return requestUrl;
    }
}

/**
 * 前端上传文件到OSS，功能未完成，视频选择组件无需具有上传功能
 * @param {*} file : 文件对象
 * @param {bool} isPublicPub : 是否上传到公开库
 * @param {string} uploadDirName : 上传到oss仓库的哪个文件夹
 */
export async function videoPickerUploadVideo(file, isPublicPub, uploadDirName) {
    if (!file) return;
    let tokenJson = await apiRequest('/gk/ops/sts/getToken?type=oss', { method: 'GET', credentials: 'include' });
    if (tokenJson.status != 1) {
        message.warning('获取oss token发生错误');
        return;
    }
    let result = tokenJson.obj;
    let client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.securityToken,
        endpoint: 'https://oss-cn-beijing.aliyuncs.com',
        bucket: makeSureBucket(isPublicPub)
    });
    let fileName = file.name;
    let putResponse = await client.multipartUpload(fileName, file, {
        progress: function(p) {
            return function(done) {
                p = p * 100;
                if (p >= 100) {
                   message.success('上传成功');
                }
                done();
            };
        },
    });
    let requestUrl = (putResponse && putResponse.res && putResponse.res.requestUrls && putResponse.res.requestUrls[0]) || '';
    requestUrl = getCleanUrl(requestUrl);
    return;
}

/** 如果是非公共读的文件的话，那么还需要获取视频能够播放的有效链接，参数key并非文件名而是文件的key */
export async function getAvailableVideoUrl(key) {
    let url = `/api/course/signedUrl?key=${key}`;
    let json = await apiRequest(url);    
    if (json.status !== 1) {
        message.warning('获取资源的有效播放链接发生错误');
        return '';
    }
    return json.obj;
}
