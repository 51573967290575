import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';
import { notification } from 'antd';

/** 当登录失效的时候清除本地token */
const resetCookie = () => {
  Cookies.remove('accessToken');
  localStorage.removeItem('routers');
  localStorage.removeItem('user');
}

/**
 * @param {String} url : 请求链接
 * @param {Object} options : 请求头参数
 * @param {Boolean} cb : 请求失败回调函数
 */
const apiRequest = (url, options, cb) => {
  options = {
    headers: {},
    ...options
  };
  options.headers['Sin-Access-Token'] = Cookies.get('accessToken');
  options.headers['Sin-UserAgent-Sign'] = 'SintureGK-WEB';
  options.headers['Sin-Client-Type'] = 3;
  return new Promise((resolve, reject) => {
    fetch(url, options).then(response => response.json())
      .then(json => {
        if (json.status === 1) {          // 请求成功，返回数据
          resolve(json);
        } else if (json.status === 3 || json.status === 500) {   // 未登录或者登陆失效          
          resetCookie();
          notification.error({
            message: `温馨提示 status： ${json.status}`,
            description: `${json.errorMes || '服务端返回异常'}`,
          });
          setTimeout(() => {
            window.location = '/login';
          }, 500)
        } else {                          // 请求失败情况 status==2或其他异常的情况
          notification.error({
            message: `温馨提示 status： ${json.status}`,
            description: `${json.errorMes || '服务端返回异常'}`,
          });
          cb && cb();
          reject(json);
        }
      }).catch(e => {
        reject(e);
      })
  })
}

const checkUrl = url => {
  if (!url.startsWith("/gk/ops")) {
    if (url[0] === "/") {
      url = "/gk/ops" + url
    } else {
      url = "/gk/ops/" + url
    }
  }
  return url
}

function appendQueryParams(url, options) {
  const [baseUrl, queryString] = url.split('?');  // 分离 URL 和已存在的查询参数
  const params = new URLSearchParams(queryString); // 将已有的查询参数转换为 URLSearchParams 对象

  // 将 options 对象中的 key-value 添加到查询参数中
  for (const [key, value] of Object.entries(options)) {
    params.set(key, value);  // 若 key 已存在则覆盖，不存在则添加
  }

  const queryParamsString = params.toString();  // 生成拼接好的查询参数字符串
  return queryParamsString ? `${baseUrl}?${queryParamsString}` : baseUrl;  // 返回完整的相对路径 URL
}

export const post = (url, data) => {
  url = checkUrl(url)
  let config = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    credentials: 'include'
  };
  return apiRequest(url, config).then(response => {
    return response.obj
  })
}

export const get = (url, options, cb) => {
  url = checkUrl(url)
  url = appendQueryParams(url, options||{})
  return apiRequest(url, options, cb).then(response => {
    return response.obj
  })
}

export default apiRequest;