import PropTypes from "prop-types"
import SearchList from "../../../../../components/SearchList"
import { useGet } from "../../../../../hooks/useGet"
import { questionRender, genQuestionIdTableItem, genCategoryIdTableItem } from "../../../../../utils/ui"
import { useState } from "react"
import { Table, Modal, Button } from "antd"
import { obj2oplist, examTypeEnum } from "../../../../../common/consts"

export default function PickQuestionList(props) {
  const enums = useGet("/interview/condition")
  const currentSelectedQuestionIdList = props.value || []
  const [activeType, setType] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const tableColumnList = [
    genQuestionIdTableItem(),
    genCategoryIdTableItem(enums?.categories),
    {
      title: "试题问题",
      dataIndex: "question",
      width: "50%",
      render: v => {
        return questionRender(v)
      },
    },
    {
      title: "试卷名称",
      dataIndex: "source",
    }
  ]

  const returnSchollList = () => {
    const recruitTypeSchoolList = enums?.recruitTypeSchool || []
    const hitIdx = recruitTypeSchoolList.findIndex(obj => obj.id === activeType)
    if (hitIdx === -1) {
      return []
    }
    const hitObj = recruitTypeSchoolList[hitIdx]
    return hitObj?.subset || []
  }

  const returnYearList = () => {
    const years = enums?.years || []
    return years.map(v => ({ name: v, value: v }))
  }

  let searchSchemaList = [
    {
      label: "试题材料试卷关键字",
      key: "keyword",
      type: "input",
    },
    {
      label: "试题类型",
      key: "categoryId",
      type: "select",
      oplist: enums?.categories || [],
      opk: "id",
      opv: "name",
    },
    {
      label: "年份",
      key: "year",
      type: "select",
      oplist: returnYearList(),
    },
    {
      label: "多元升学类型",
      key: "type",
      type: "select",
      oplist: enums?.recruitTypeSchool || [],
      opk: "id",
      opv: "name",
      changeCallback: setType,
    },
    {
      label: "试卷类型",
      key: "examineType",
      type: "select",
      oplist: obj2oplist(examTypeEnum, Number),
    }
  ]
  if (activeType) {
    const item = {
      label: "学校",
      key: "school",
      type: "select",
      oplist: returnSchollList(),
      opk: "id",
      opv: "name",
    }
    const idx = searchSchemaList.findIndex(obj => obj.key === item.key)
    if (idx === -1) {
      searchSchemaList.push(item)
    }
  } else {
    const idx = searchSchemaList.findIndex(obj => obj.key === "school")
    if (idx !== -1) {
      searchSchemaList.splice(idx, 1)
    }
  }

  const addQuestionHandler = () => {
    setShowModal(true)
  }

  const delRepeat = arr => {
    arr = arr.filter(item => !!item)
    const obj = {}
    for (const item of arr) {
      obj[item.id] = item
    }
    const answer = []
    for (let k in obj) {
      answer.push(obj[k])
    }
    return answer
  }

  const rowSelectionObj = {
    type: "checkbox",
    onSelect: (record, selected) => {
      const newArr = [...currentSelectedQuestionIdList];
      const newQuestionArr = [...(props.questionDetailList || [])];
      if (selected) {
        newArr.push(record.id);
        newQuestionArr.push(record);
      } else {
        const idx = newArr.findIndex((qid) => qid === record.id);
        if (idx !== -1) {
          newArr.splice(idx, 1);
        }
        const idx2 = newQuestionArr.findIndex(qobj => qobj.id === record.id)
        if (idx2 !== -1) {
          newQuestionArr.splice(idx2, 1);
        }
      }
      props.onChange(newArr);
      props.updateQuestionDetailList(newQuestionArr);
    },
    // 全选或者取消全选时触发
    onSelectAll: (selectd, selectedRows, changeRows) => {
      const newArr = [...currentSelectedQuestionIdList];
      const newQuestionDetailArr = [...(props.questionDetailList || [])];
      const changeRowKeys = changeRows.map((obj) => obj.id);
      if (selectd) {
        // 选中的话，合并一下newArr和changeRows数组，不过需要去重
        const mayRepeatArr = [...newArr, ...changeRowKeys];
        const mayRepeatQuestionDetailArr = [...newQuestionDetailArr, ...selectedRows];
        const arr2 = Array.from(new Set(mayRepeatArr));
        props.onChange(arr2);
        props.updateQuestionDetailList(delRepeat(mayRepeatQuestionDetailArr));
      } else {
        // 取消选中的话，如果newArr里面存在changeRowKeys的话，把它给删掉
        changeRowKeys.forEach((id) => {
          const idx = newArr.findIndex((qid) => qid === id);
          if (idx !== -1) {
            newArr.splice(idx, 1);
          }
          const idx2 = newQuestionDetailArr.findIndex(qobj => qobj.id === id);
          if (idx2 !== -1) {
            newQuestionDetailArr.splice(idx2, 1)
          }
        });
        props.onChange(newArr);
        props.updateQuestionDetailList(newQuestionDetailArr);
      }
    },
    selectedRowKeys: currentSelectedQuestionIdList,
  }

  return (
    <div>
      <Button onClick={addQuestionHandler} type="primary" style={{ marginBottom: 15 }}>新增题目</Button>
      <Table
        rowKey="id"
        columns={tableColumnList}
        dataSource={props.questionDetailList || []}
        rowSelection={rowSelectionObj}
        pagination={{
          showTotal: v => `共选中${v}道题目`
        }}
      />

      <Modal
        open={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        width={1200}
      >
        <SearchList
          url="/question/page"
          rowKey="id"
          tableColumns={tableColumnList}
          searchSchema={searchSchemaList}
          tableRowSelection={rowSelectionObj}
          resetCb={() => setType(null)}
        />
      </Modal>
    </div>
  )
}

PickQuestionList.propTypes = {
  value: PropTypes.any, // 所勾选中的试题id列表
  onChange: PropTypes.func, // 勾选试题的回调方法
}