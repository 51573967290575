import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'antd';

const ExpandableContainer = ({ children, height }) => {
  const [expanded, setExpanded] = useState(false);
  const [contentOverflow, setContentOverflow] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const contentHeight = contentRef.current.scrollHeight;
    if (contentHeight > height) {
      setContentOverflow(true);
    } else {
      setContentOverflow(false);
    }
  }, [children, height]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div
        ref={contentRef}
        style={{
          maxHeight: expanded ? 'none' : `${height}px`,
          overflow: 'hidden',
          transition: 'max-height 0.3s ease',
        }}
      >
        {children}
      </div>
      {contentOverflow && (
        <Button style={{ marginTop: 10 }} type="link" onClick={toggleExpand}>
          {expanded ? '收起' : '展开'}
        </Button>
      )}
    </div>
  );
};

export default ExpandableContainer;
