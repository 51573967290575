import React from "react"
import { Card, Tooltip } from "antd"
import SearchList from "../../../components/SearchList"
import P from "../../../components/P"
import { Link } from "react-router-dom"

export default class CommentsTemplateList extends React.Component {
  columnList = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "模版名称",
      dataIndex: "templateName",
    },
    {
      title: "模板内容",
      dataIndex: "content",
      width: "40%",
      render: (text, record) => {
        const getTooltip = (str) => {
          return <div dangerouslySetInnerHTML={{ __html: str ? str : '' }}></div>
        }
        return (
          <Tooltip title={getTooltip(text)}>
            <div className="ellipsis1" style={{height: '22px', maxWidth: '500px', overflow: 'hidden'}} dangerouslySetInnerHTML={{__html: text}}/>
          </Tooltip>
        )
      }
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (v) => {
        return (
          <P code="examine:template:edit" type="link">
            <Link to={`/commentsTemplateList/detail/${v}`}>
              编辑
            </Link>
          </P>
        )
      }
    }
  ]

  render() {
    return (
      <Card>
        <SearchList
          url="/gk/ops/examine/template/list"
          method="get"
          tableColumns={this.columnList}
          recordKey="obj"
          pagination={false}
        >
          <P code="examine:template:edit" style={{ marginBottom: 20 }} type="primary">
            <Link to="/commentsTemplateList/new">
              新增
            </Link>
          </P>
        </SearchList>
      </Card>
    )
  }
}