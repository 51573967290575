import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Editor from "../Editor/index";
import {
    fetchCourses,
    setCurrentPage
} from "../../containers/CourseList/action";
import { fetchCourseCategories } from "../../containers/ModuleSetting/actions";
import {
    fetchExamines,
    setActivePage
} from "../../containers/DailyExamineList/actions";
import { Select, Table, Checkbox, message, Input ,Button } from 'antd';
import apiRequest from '../../common/request';
import {
    MINI_DATA_TYPE,
    RICH_DATA_TYPE,
    COURSE_DATA_TYPE,
    DAILY_DATA_TYPE
} from '../../containers/BannerRelease/action';
import './index.scss';

const { Option } = Select;

const PAGE_SIZE = 10;
const DATA_TYPES = [
    { cn: '资讯', value: RICH_DATA_TYPE },
    { cn: '课程', value: COURSE_DATA_TYPE },
    { cn: '每日一练', value: DAILY_DATA_TYPE },
    // { cn: '申论批改反馈', value: 4 },
    { cn: '客户端估分', value: 5 },
    { cn: '客户端职位查询', value: 6 },
    { cn: '跳转小程序类型', value: MINI_DATA_TYPE }
];
const MINI_TYPES = [
    { cn: '主小程序', value: 0 },
    { cn: '常识积累', value: 1 },
    { cn: '估分', value: 2 },
    { cn: '职位查询', value: 3 },
    { cn: '下载客户端', value: 4 }
];


class BannerChoseDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingExists: false,               // 加载已选课程或者已选每日一练
            selectObj: [],                      // 已选课程或者每日一练的详情数据
            /** 下面的是在线课程列表搜索条件 */
            categoryId: '0',                    // 这是课程分类
            title: null,                          // 这是课程名字关键字 
        }
    }

    componentDidMount() {
        this.fetchCourseHandler();
        this.fetchDailyHandler();
        this.props.dispatch(fetchCourseCategories());
        let dataId = this.props.bannerVO && this.props.bannerVO.dataId;
        if (dataId) {                           // dataId是可以为0的，比如当dataId的值表示的是主小程序的时候，但是对于需要加载数据的情况来说是不可能为0的
            this.fetchDetail(dataId);
        }
    }

    /** 获取每日一练或者课程的详情方法 */
    fetchDetail = async id => {
        let url = '';
        if (this.props.bannerVO.dataType === COURSE_DATA_TYPE) {            // 说明是推课程
            url = `/gk/ops/course/details/${id}`;
        } else if (this.props.bannerVO.dataType === DAILY_DATA_TYPE) {      // 说明是推每日一练
            url = `/gk/ops/dailyExercise/details/${id}`;
        } else {                                                            // 目前不仅课程以及每日一练具有dataId，而且其它地方上面也具有dataId，但是其它地方的dataId是不需要加载数据的
            return;
        }
        this.setState({ loadingExists: true });
        let json = await apiRequest(url);
        if (json.status !== 1) { message.error(json.errorMes || '请求详情发生错误'); return; }
        this.setState({ loadingExists: false, selectObj: [json.obj] });
    }

    /** 设置新的bannerVO对象 */
    setNewBannerVO = (key, value) => {
        let bvo = {...this.props.bannerVO};
        let newObj = { ...bvo, [key]: value };
        if (key === 'dataType') {
            delete newObj['dataId'];
            newObj.content = '';
        }
        this.props.updateBannerVo(newObj);
    }

    /** 根据categoryId找出分类名 */
    findCategoryName = id => {
        if (Object.prototype.toString.call(this.props.categories) !== "[object Array]") return '';
        let obj = this.props.categories.find(obj => obj.id == id) || {};
        return obj.name || '';
    }

    /** 点击课程的勾选框 */
    checkStatusChange = (event, id) => {
        let checked = event.target.checked;
        let newObj;
        if (checked) {                              // 如果是勾选上去
            newObj = { ...this.props.bannerVO, dataId: id };
            this.fetchDetail(id);
        } else {                                    // 如果是取消勾选
            newObj = {...this.props.bannerVO};
            delete newObj['dataId'];
        }
        this.props.updateBannerVo(newObj);
    }

    /** 获取课程列表的封装函数 */
    fetchCourseHandler = () => {
        let params = { page: this.props.courseCurrentPage, pageSize: PAGE_SIZE };
        params.categoryId = this.state.categoryId;
        let checkKeys = [ 'title' ];
        checkKeys.map(key => (this.state[key] != null) && (params[key] = this.state[key]));
        this.props.dispatch(fetchCourses(params));
    }

    /** 获取每日一练的封装函数 */
    fetchDailyHandler = () => {
        this.props.dispatch(fetchExamines(this.props.dailyActivePage, PAGE_SIZE));
    }

    /** 课程改变页码 */
    coursePaginationChange = async page => {
        await this.props.dispatch(setCurrentPage(page));
        this.fetchCourseHandler();
    }

    /** 每日一练改变页码 */
    handleDailySelect = async page => {
        await this.props.dispatch(setActivePage(page));
        this.fetchDailyHandler();
    }

    /** 生成antd table的配置文件 */
    generateTableConfig = () => {
        let columns = null;
        if (this.props.bannerVO.dataType === COURSE_DATA_TYPE) {            // 课程的配置
            columns = [
                {
                    title: '课程标题',
                    render: (text, record) => <Link to={`/course/${record.id}`}>{record.title}</Link>
                },
                {
                    title: '分类',
                    render: (text, record) => <span>{this.findCategoryName(record.categoryId)}</span>
                },
                { title: '起止时间', dataIndex: 'period' },
                {
                    title: '选择',
                    render: (text, record) => <Checkbox onChange={event => this.checkStatusChange(event, record.id)} checked={this.props.bannerVO.dataId == record.id}></Checkbox>
                }
            ];
        }
        if (this.props.bannerVO.dataType === DAILY_DATA_TYPE) {             // 每日一练的配置
            columns = [
                {
                    title: '标题',
                    render: (text, record) => <Link to={`/dailyExamine/${record.id}`}>{record.title}</Link>
                },
                { title: '时长（秒）', dataIndex: 'limitTime' },
                { title: '上线时间', dataIndex: 'publishDate' },
                {
                    title: '选择',
                    render: (text, record) => <Checkbox onChange={event => this.checkStatusChange(event, record.id)} checked={this.props.bannerVO.dataId == record.id}></Checkbox>
                }
            ];
        }
        return columns;
    }

    /** 根据banner类型渲染对应的内容区域 */
    renderByCondition = () => {
        let renderFun = () => <span></span>;
        this.props.bannerVO.dataType === MINI_DATA_TYPE && (renderFun = this.renderMiniCondition);
        this.props.bannerVO.dataType === RICH_DATA_TYPE && (renderFun = this.renderRichCondition);
        this.props.bannerVO.dataType === COURSE_DATA_TYPE && (renderFun = this.renderCourseCondition);
        this.props.bannerVO.dataType === DAILY_DATA_TYPE && (renderFun = this.renderDailyCondition);
        return renderFun();
    }

    /** 如果banner类型是小程序的话，那么渲染小程序类型 */
    renderMiniCondition = () => {
        return (
            <div className="inputRow">
                <label className="labelWidth">小程序类型</label>
                <Select className="w360" value={this.props.bannerVO.dataId} onChange={value => this.setNewBannerVO('dataId', value)}>
                    {
                        MINI_TYPES.map((obj, idx) => <Option key={idx} value={obj.value}>{obj.cn}</Option>)
                    }
                </Select>
            </div>
        );
    }

    /** 如果banner类型是资讯类型的话，那么渲染富文本输入 */
    renderRichCondition = () => {
        return (
            <div className="inputRow">
                <label className="labelWidth">资讯详情</label>
                <Editor
                    content={this.props.bannerVO.content}
                    onChange={value => this.setNewBannerVO('content', value)}
                    imgPath='background/banner/news'
                />
            </div>
        );
    }

    /** 筛选条件的值输入监听函数 */
    valueChangeHandler = (key, value, realTime=true) => {
        this.setState({ [key]: value }, async () => {
            await this.props.dispatch(setActivePage(1));
            realTime && this.fetchCourseHandler();
        });
    }  

    /** 清空搜索条件 */
    resetSearch = () => {
        this.setState({ 
            categoryId: '0', title: null,
        }, async () => { 
            await this.props.dispatch(setCurrentPage(1));
            this.fetchCourseHandler(); 
        });
    }

    /**改变搜索条件 */
    resetSelect = async() => {
            await this.props.dispatch(setCurrentPage(1));
            this.fetchCourseHandler(); 
    }

    /** 如果banner类型是课程类型的话，那么渲染课程选择 */
    renderCourseCondition = () => {
        let columns = this.generateTableConfig();
        return (
            <div className="courseArea">
                {/** 接下来是筛选条件输入区域 */}               
                <div className="flex">
                    <div className="margin5">
                        <label>分类</label>
                            <Select 
                                value={this.state.categoryId}
                                className="selectWidth"
                                dropdownMatchSelectWidth={false}
                                onChange={value => this.valueChangeHandler('categoryId', value)}
                            >
                            {this.props.categories && this.props.categories.map((cat, idx) => {
                            return <Option key={idx} value={cat.id.toString()}>{cat.name}</Option>
                            })}
                            </Select>
                    </div>                   
                </div>
                <div className="margin105">
                    <Input
                        className="w500"
                        id="title"
                        placeholder="输入标题的关键词查询"
                        value={this.state.title}
                        onChange={event => this.valueChangeHandler('title', event.target.value, false)}
                    />
                    <Button onClick={this.resetSelect} type="primary" className="mr15">搜索</Button>
                    <Button onClick={this.resetSearch} type="danger">清空搜索条件</Button>
                </div>
                      

                {
                    this.props.bannerVO.dataId ?
                    <Table
                        columns={columns}
                        loading={this.state.loadingExists}
                        dataSource={this.state.selectObj}
                        rowKey={record => record.id}
                        title={() => '已选课程'}
                        pagination={{ pageSize: 10000 }}
                    />
                    :
                    <div></div>
                }

                <Table
                    columns={columns}
                    dataSource={this.props.courseList || []}
                    rowKey={record => record.id}
                    title={() => '在线课程列表'}
                    pagination={{
                        pageSize: PAGE_SIZE,
                        current: this.props.courseCurrentPage,
                        loading: this.props.coursesIsFetching,
                        total: this.props.courseTotalElements,
                        onChange: this.coursePaginationChange,
                    }}
                />
            </div>
        );
    }

    /** 如果banner类型是每日一练的话，那么渲染每日一练选择 */
    renderDailyCondition = () => {
        let columns = this.generateTableConfig();
        return (
            <div className="dailyArea">
                {
                    this.props.bannerVO.dataId ?
                    <Table
                        columns={columns}
                        loading={this.state.loadingExists}
                        dataSource={this.state.selectObj}
                        rowKey={record => record.id}
                        title={() => '已选每日一练'}
                        pagination={{ pageSize: 1000 }}
                    />
                    :
                    <div></div>
                }

                <Table
                    columns={columns}
                    dataSource={this.props.dailyExamineList}
                    rowKey={record => record.id}
                    title={() => '每日一练列表'}
                    pagination={{
                        loading: this.props.dailyExaminesIsFetching,
                        pageSize: PAGE_SIZE,
                        current: this.props.dailyActivePage,
                        total: this.props.dailyTotalElements,
                        onChange: this.handleDailySelect,
                    }}
                />
            </div>
        );
    }

    render() {
        let { bannerVO } = this.props;
        return (
            <div className="bannerChoseDetail">
                <div className="inputRow">
                    <label className="labelWidth">banner类型</label>
                    <Select className="w360" value={bannerVO.dataType} onChange={value => this.setNewBannerVO('dataType', value)}>
                        {
                            DATA_TYPES.map((obj, idx) => <Option key={idx} value={obj.value}>{obj.cn}</Option>)
                        }
                    </Select>
                </div>           
                {              
                this.renderByCondition() 
                }
            </div>
        );
    }
}

// BannerChoseDetail.propTypes = {
//     bannerVO: PropTypes.object,                     // 整个banner的详情数据
//     updateBannerVo: PropTypes.func,                 // 子组件调用父组件的方法进行更新操作
// };

// BannerChoseDetail.defaultProps = {
//     bannerVO: {
//         dataType: '',                       // 这是banner类型
//         dataId: 0,                          // banner所关联内容的ID
//         content: '',                        // 资讯banner所对应的富文本内容
//     }
// };

function mapStatetoProps(state) {

    const {
        items: courseList,
        isFetching: coursesIsFetching,
        totalElements: courseTotalElements,
        currentPage: courseCurrentPage,
    } = state.courseList;

    const {
        items: categories
    } = state.courseCategories;

    const {
        items: dailyExamineList,
        isFetching: dailyExaminesIsFetching,
        activePage: dailyActivePage,
        totalElements: dailyTotalElements
    } = state.dailyExamines;

    return {
        courseList,
        coursesIsFetching,
        categories,
        dailyExamineList,
        dailyExaminesIsFetching,
        courseTotalElements,
        courseCurrentPage,
        dailyActivePage,
        dailyTotalElements
    };

}

export default connect(mapStatetoProps)(BannerChoseDetail);
