import { Tooltip, Modal } from "antd"
import "./ui.scss"
import { useState } from "react"
import ExpandableContainer from "../components/Expandable"

/**
 * 多行渲染问题
 * @param {*} question 
 * @returns 
 */
export const questionRender = (question) => {
  const getTooltip = (str) => {
    return <div dangerouslySetInnerHTML={{ __html: str ? str : "" }}></div>;
  }

  const styleObj = {
    width: '100%',
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  };

  return (
    <Tooltip title={getTooltip(question)}>
      <div style={styleObj} dangerouslySetInnerHTML={{ __html: question ? question : "" }}></div>
    </Tooltip>
  );

  // return (
  //   <Tooltip title={getTooltip(question)}>
  //     <div
  //       style={{
  //         flexBasis: "62%",
  //         maxWidth,
  //         paddingRight: "20px",
  //         height: "24px",
  //       }}
  //       className="ellipsis1"
  //       dangerouslySetInnerHTML={{ __html: question }}
  //     />
  //   </Tooltip>
  // )
}

// 试题类型在列表中的展示
export const genCategoryIdTableItem = (categoryList) => {
  return {
    title: "试题类型",
    dataIndex: "categoryId",
    render: v => {
      if (!Array.isArray(categoryList)) {
        return ""
      }
      const hitObj = categoryList.find(obj => obj.id == v) || {}
      return hitObj?.name
    },
  }
}

export const genQuestionIdTableItem = () => ({
  title: "试题ID",
  dataIndex: "id",
  render: v => {
    return v
  }
})

export const questionFullRender = (questionDetail) => {
  return (
    <div className="questionFul1210-210-212-1">
      <div style={{ marginBottom: 15 }}>
        <div>问题：</div>
        <ExpandableContainer height={200}>
          <p dangerouslySetInnerHTML={{ __html: questionDetail.question }} />
        </ExpandableContainer>
      </div>
      {/* <div className="flexrc903202 childDiv232903">
        <span className="questionTest2323">试题：</span>
        <span dangerouslySetInnerHTML={{ __html: questionDetail.question }} style={{ fontSize: 14 }}></span>
      </div> */}
      {
        questionDetail?.material ? (
          <div>
            <div>材料：</div>
            <ExpandableContainer height={200}>
              <p dangerouslySetInnerHTML={{ __html: questionDetail?.material }} />
            </ExpandableContainer>
          </div>
        ) : null
      }
      {/* {
        questionDetail?.material && <div className="flexrc903202 childDiv232903">
          <span className="questionTest2323">材料：</span>
          <span dangerouslySetInnerHTML={{ __html: questionDetail?.material }} style={{ 'fontSize': '14px' }}></span>
        </div>
      } */}
    </div>
  )
}

export function VideoRender(props) {
  const [videoModal, setVideoModal] = useState(false)

  return (
    <div>
      {
        props.videoUrl ? (
          <a type="link" onClick={(event) => {
            event.preventDefault()
            setVideoModal(true)
          }}>视频-点我播放</a>
        ) : "未上传视频"
      }
      <Modal title="学生视频" footer={null} visible={videoModal} onCancel={() => setVideoModal(false)}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <video width="480" height="380" src={props.videoUrl} controls autoPlay="autoplay">您的浏览器不支持 HTML5 video 标签</video>
        </div>
      </Modal>
    </div>
  ) 
}