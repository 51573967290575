/**
 * 百分号传参、设置组件.
 * 组件一定会保证透传给父组件的值是/100的，因此在显示的时候*100
 */
import { InputNumber } from "antd"
import PropTypes from "prop-types"
import styles from "./index.scss"

const sureNum = num => {
  if (Number.isNaN(num)) {
    return 0
  }
  return num
}

const accDiv = (n1, n2) => {
  return sureNum((n1 / n2)).toFixed(2)
}

const accMul = (n1, n2) => {
  return sureNum((n1 * n2)).toFixed(2)
}

// 透传给父组件的应该/100

const Percent = props => {
  const valChangeHandler = val => {
    if (val == null) {
      return
    }
    const v2 = accDiv(val, 100)
    if (Number.isNaN(v2)) {
      return
    }
    props.onChange(v2)
  }

  const valueFact = () => {
    if (props.value == undefined) {
      return ""
    }
    return accMul(props.value, 100)
  }

  const pressEnterHandler = event => {
    if (!props.needJumpToNextInput) {
      return
    }
    // 严重依赖antd所生成的结构进行硬编码，不太可读，但是写起来很方便，最通用的方法是遍历一下form这棵dom树，
    // 然后判断某个div[class="ant-col"]是否具有event.target这个字节点，如果具有的话，那么则说明命中
    const topParent = event.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
    const targetNode = topParent?.nextSibling?.childNodes?.[0]?.childNodes?.[0]?.childNodes?.[1]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[1]?.childNodes[0]
    if (targetNode && targetNode.tagName == "INPUT") {
      targetNode.focus()
    }
  }

  let minVal = Number.MIN_SAFE_INTEGER
  if (props.min != undefined) {
    minVal = props.min
  }
  const maxVal = props.max || Number.MAX_SAFE_INTEGER

  const styleObj = {}
  if (props.width != undefined) {
    styleObj.width = props.width
  }

  return (
    <div style={styleObj} className={styles.inputWrapper}>
      <InputNumber
        addonAfter="%"
        placeholder="请输入"
        value={valueFact()}
        min={minVal}
        max={maxVal}
        onChange={valChangeHandler}
        disabled={props.disabled}
        onPressEnter={pressEnterHandler}
        className={styles.flexg}
        precision={props.precision}
      />
      <span className={styles.btn}>%</span>
    </div>
  )
}

Percent.propTypes = {
  value: PropTypes.any,
  min: PropTypes.number, // 最小值
  max: PropTypes.number, // 最大值
  disabled: PropTypes.bool, // 是否禁用
  needJumpToNextInput: PropTypes.bool,
}

export default Percent