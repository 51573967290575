import { Card, Tabs, Descriptions, Modal } from "antd"
import { post } from "../../../common/request"
import { useState, useEffect } from "react"
import { questionFullRender, VideoRender } from "../../../utils/ui"
import UserAbilityReport from "../../../components/UserAbilityReport"
import P from "../../../components/P"

export default function UserAnswerDetail(props) {
  const exerciseId = props.match?.params?.examId
  const userId = props.match?.params?.userId

  const [answerList, setAnswerDetail] = useState([])
  const [loadingAnswerDetail, setLoadingAnswerDetail] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const clickReportHandler = () => setShowModal(true)

  const renderCardTitle = () => {
    return (
      <div style={{ display: "flex" }}>
        <div>用户答题详情</div>
        <div style={{ flexGrow: 1 }} />
        <P onClick={clickReportHandler} code="ability:report:edit" type="primary">测评报告</P>
      </div>
    )
  }

  useEffect(() => {
    if (!exerciseId || !userId) {
      return
    }
    setLoadingAnswerDetail(true)
    const postData = { exerciseId, userId }
    post("/ability/exercises/question/detail", postData).then(res => {
      setAnswerDetail(res || [])
    }).finally(() => {
      setLoadingAnswerDetail(false)
    })
  }, [exerciseId, userId])

  return (
    <Card title={renderCardTitle()} loading={loadingAnswerDetail}>
      <Tabs>
        {
          answerList.map(userAnswerObj => {
            return (
              <Tabs.TabPane tab={`问题ID-${userAnswerObj.questionId}`} key={userAnswerObj.questionId}>
                <Card title="用户答题数据">
                  <Descriptions column={1}>
                    <Descriptions.Item label="答题时间">{userAnswerObj?.gmtCreate}</Descriptions.Item>
                    <Descriptions.Item label="作答内容">{userAnswerObj?.remark}</Descriptions.Item>
                    <Descriptions.Item label="视频">
                      <VideoRender videoUrl={userAnswerObj.videoUrl} />
                    </Descriptions.Item>
                    <Descriptions.Item label="音频">
                      {
                        userAnswerObj?.voiceUrl ? (
                          <audio style={{ 'height': '35px', width: '250px' }} controls="controls" src={userAnswerObj.voiceUrl}>您的浏览器不支持 audio 标签</audio>
                        ) : <div>学员未上传</div>
                      }
                    </Descriptions.Item>
                  </Descriptions>
                </Card>

                <Card title="试题数据">
                  { questionFullRender(userAnswerObj) }
                </Card>
              </Tabs.TabPane>
            )
          })
        }
      </Tabs>

      <Modal
        title="报告弹窗"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={1300}
      >
        <UserAbilityReport
          userId={userId}
          cancelCb={() => setShowModal(false)}
          successCb={() => setShowModal(false)}
        />
      </Modal>
    </Card>
  )
}