import { Card, Descriptions } from "antd"
import { useState, useEffect } from "react"
import { get } from "../../../common/request"
import { questionFullRender, VideoRender } from "../../../utils/ui"
import { payStatusEnum } from "../../../common/consts"
import DianPin from "../../../components/DianPin"

export default function(props) {
  const id = props.match?.params?.id
  const [questionDetail, setQuestionDetail] = useState({})
  const [loadDetail, setLoadingDetail] = useState(false)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    if (!id) {
      return
    }
    setLoadingDetail(true)
    get(`/user/question/detail?id=${id}`).then(res => {
      setQuestionDetail(res || {})
    }).finally(() => {
      setLoadingDetail(false)
    })
  }, [id, reload])

  return (
    <div className="why2382301sshjdshjd2302">
      <Card title="试题数据" loading={loadDetail}>
        { questionFullRender(questionDetail) }
      </Card>

      <Card title="用户答题数据" loading={loadDetail}>
        <Descriptions column={1}>
          <Descriptions.Item label="答题时间">{questionDetail?.gmtCreate}</Descriptions.Item>
          <Descriptions.Item label="作答内容">{questionDetail?.remark}</Descriptions.Item>
          <Descriptions.Item label="视频">
            <VideoRender videoUrl={questionDetail.videoUrl} />
          </Descriptions.Item>
          <Descriptions.Item label="音频">
            {
              questionDetail?.voiceUrl ? (
                <audio style={{ 'height': '35px', width: '250px' }} controls="controls" src={questionDetail.voiceUrl}>您的浏览器不支持 audio 标签</audio>
              ) : <div>学员未上传</div>
            }
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card title="老师点评数据" loading={loadDetail}>
        <div style={{ marginBottom: 15 }}>
          <DianPin answerVO={questionDetail} reloadHandler={() => setReload(!reload)} />
        </div>
        <Descriptions column={1}>
          <Descriptions.Item label="老师评论">
            <span dangerouslySetInnerHTML={{ __html: questionDetail.comment || "老师暂未点评" }}></span>
          </Descriptions.Item>
          <Descriptions.Item label="语音评论">
            {
              questionDetail.commentUrl ? (
                <audio style={{ 'height': '35px', width: '250px' }} controls="controls" src={questionDetail.commentUrl}>您的浏览器不支持 audio 标签</audio>
              ) : <div>老师未上传</div>
            }
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card title="用户数据" loading={loadDetail}>
        <Descriptions>
          <Descriptions.Item label="支付状态">{payStatusEnum[questionDetail?.payStatus]}</Descriptions.Item>
          <Descriptions.Item label="用户ID">{questionDetail?.userId}</Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  )
}