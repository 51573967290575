import { Card, Form, Row, Col, Button, message } from "antd"
import { useState, useEffect } from "react"
import { get, post } from "../../../common/request"
import { ablityIsShowEnum, obj2oplist, ablityNotShow } from "../../../common/consts"
import PickQuestionList from "./comp/pickQuestionList/index"
import FormList from "../../../components/FormItem"

export default function(props) {
  const id = props.match?.params?.id
  const [formInstance] = Form.useForm()
  const [questionDetail, setQuestionDetail] = useState({})
  const [loadingDetail, setLoadingDetail] = useState(false)

  const width = 500
  const formListConfig = [
    {
      label: "标题",
      key: "title",
      type: "input",
      required: true,
      w: width,
    },
    {
      label: "是否显示",
      key: "isShow",
      type: "radio",
      oplist: obj2oplist(ablityIsShowEnum, Number),
      required: true,
      initV: ablityNotShow,
    },
    {
      label: "答题时间/分钟",
      key: "mins",
      type: "numberInput",
      required: true,
      w: width,
    },
    {
      label: "已勾选题目",
      key: "questionIds",
      type: "custom",
      render: v => (
        <PickQuestionList
          questionDetailList={questionDetail?.questions || []}
          updateQuestionDetailList={newArr => {
            const newObj = {
              ...questionDetail,
              questions: newArr,
            }
            setQuestionDetail(newObj)
          }}
        />
      ),
      required: true,
    }
  ]

  const saveHandler = async () => {
    const values = await formInstance.validateFields()
    const postData = {
      ...questionDetail,
      ...(values || {}),
    }
    delete postData.questions
    post("/ability/exercises/saveOrUpdate", postData).then(() => {
      message.success("操作成功")
      props.history.goBack()
    })
  }

  const returnCardTitle = () => {
    // eslint-disable-next-line
    if (location.href.includes("/ablityTest/list/new")) {
      return "新增能力测试套题"
    }
    return "能力测试套题详情"
  }

  useEffect(() => {
    if (!id) {
      return
    }
    setLoadingDetail(true)
    get(`/ability/exercises/detail?id=${id}`).then(res => {
      formInstance.setFieldsValue(res)
      setQuestionDetail(res)
    }).finally(() => {
      setLoadingDetail(false)
    })
  }, [id])

  return (
    <Card title={returnCardTitle()} loading={loadingDetail}>
      <Form form={formInstance} labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
        <FormList
          span={24}
          list={formListConfig}
        />
      </Form>
      <Row>
        <Col offset={3}>
          <Button style={{ marginTop: 20 }} onClick={saveHandler} type="primary">提交</Button>
        </Col>
      </Row>
    </Card>
  )
}