import { Card, Image, message, Popconfirm } from 'antd'
import SearchList from '../../../components/SearchList'
import P from '../../../components/P'
import { isVipEnum, renderEnum, zeroOneColor, notvip, obj2oplist } from '../../../common/consts'
import { Link } from 'react-router-dom'
import { post } from '../../../common/request'
import { useRef, useState } from 'react'
import ModalForm from '../../../components/ModalForm';

export default function AppUserList(props) {
  const [reload, setReload] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const submitRef = useRef(false)
  const searchSchemaList = [
    {
      label: '手机号',
      key: 'mobile',
      type: 'input',
    },
    {
      label: '是否VIP',
      key: 'vipStatus',
      type: 'select',
      oplist: obj2oplist(isVipEnum),
    },
    {
      label: "登录时间范围",
      key: "times",
      type: "rangePicker",
    }
  ]

  const uiSchema = [
    {
      title: "用户ID",
      dataIndex: "id",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
    },
    {
      title: "注册时间",
      dataIndex: "gmtCreate",
    },
    {
      title: "上次登录时间",
      dataIndex: "lastLoginTime",
    },
    {
      title: "昵称",
      dataIndex: "nickName",
    },
    {
      title: "头像",
      dataIndex: "avatar",
      width: 100,
      render: v => <Image src={v} />,
    },
    {
      title: "是否VIP",
      dataIndex: "isVip",
      render: v => renderEnum(v, isVipEnum, zeroOneColor)
    },
    {
      title: "操作",
      dataIndex: "id",
      width: 170,
      render: (v, rowObj) => {
        const setVip = () => {
          if (!rowObj.mobile) {
            message.warning('用户还未授权手机号，无法标记为VIP')
            return
          }
          const requestData = {
            mobiles: [rowObj?.mobile],
          }
          if (rowObj.isVip == notvip) {
            requestData.status = 1
          } else {
            requestData.status = 0
          }
          if (submitRef.current) {
            message.warning('提交中')
            return
          }
          submitRef.current = true
          post('/user/vip', requestData).then(res => {
            message.success('操作成功')
            setReload(!reload)
          }).finally(() => {
            submitRef.current = false
          })
        }

        return (
          <div>
            <P code="ability:report:edit" type="link">
              <Link to={`/userManage/appUserList/${v}/report`}>测评报告</Link>
            </P>
            <P code="user:vip:set" type="link">
              <Popconfirm
                title={rowObj.isVip == notvip ? "确定标记该学员为VIP？" : "确定取消VIP？" }
                onConfirm={setVip}
              >
                { rowObj.isVip == notvip ? '标记VIP' : '取消VIP' }
              </Popconfirm>
            </P>
          </div>
        )
      }
    }
  ]

  const batchHandler = () => {
    setShowModal(true)
  }

  const modalConfirmHandler = values => {
    const mobileStr = values?.mobiles || ""
    let mobiles = mobileStr.split(",")
    mobiles = mobiles.map(str => {
      return str.trim()
    })
    mobiles = mobiles.filter(str => !!str)
    if (!mobiles.length) {
      message.warning("至少填写一个手机号")
      return
    }
    const postData = { mobiles }
    postData.status = 1
    return post("/user/vip", postData).then(res => {
      message.success("操作成功")
      setReload(!reload)
    })
  }

  const modalFormList = [
    {
      label: "手机号数据",
      type: "textArea",
      key: "mobiles",
      required: true,
      placeholder: "多个手机号用英文下的,分割"
    }
  ]

  return (
    <Card>
      <SearchList
        url="/user/page"
        tableColumns={uiSchema}
        searchSchema={searchSchemaList}
        useCache={true}
        needReload={reload}
        timesKey="times"
        startTimeKey="startTime"
        endTimeKey="endTime"
        startTimeFormatStr="YYYY-MM-DD 00:00:00"
        endTimeFormatStr="YYYY-MM-DD 23:59:59"
      >
        <P style={{ marginBottom: 20 }} onClick={batchHandler} code="user:vip:set" type="primary">批量配置VIP</P>
      </SearchList>

      <ModalForm
        title="批量配置VIP"
        visible={showModal}
        onOk={modalConfirmHandler}
        onCancel={() => setShowModal(false)}
        formList={modalFormList}
      />
    </Card>
  )
}