import { get } from "../common/request";
import { useState, useEffect } from "react";

export const useGet = (url, defaultVal, reload) => {
  let defaultValue
  if (defaultVal == undefined) {
    defaultValue = {}
  } else {
    defaultValue = defaultVal
  }

  const [data, setData] = useState(defaultValue)
  
  useEffect(() => {
    get(url).then(res => {
      setData(res || defaultValue)
    })
  }, [reload])

  return data
}