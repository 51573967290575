import { Select, message } from "antd"
import { useState, useEffect } from "react"
import { get } from "../../common/request"

export default function UseTempLateList(props) {
  const [templateList, setTemplateList] = useState([])

  const returnOptions = () => {
    if (!templateList.length) return []
    return templateList.map(v => ({
      ...v,
      label: v.templateName,
      value: v.id,
    }))
  }

  const changeHandler = (value, options) => {
    message.success("使用成功")
    props.onChange(value, options)
  }

  useEffect(() => {
    get("/examine/template/list").then(res => {
      setTemplateList(res || [])
    })
  }, [])

  return (
    <Select
      value={null}
      style={{ width: 260 }}
      options={returnOptions()}
      placeholder="选择点评模板"
      onChange={changeHandler}
    />
  )
}