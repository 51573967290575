import { Card } from "antd"
import SearchList from "../../../components/SearchList"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { obj2oplist, submitStatusEnum, reportStatusEnum, renderEnum, zeroOneColor } from "../../../common/consts"

export default function FinishUserList(props) {
  const examId = props.match?.params?.examId
  const [defaultSearchData, setDefaultSearchData] = useState({})

  const uiSchema = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "用户名",
      dataIndex: "userName",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
    },
    {
      title: "答题时间",
      dataIndex: "gmtCreate",
    },
    {
      title: "试题数量",
      dataIndex: "questionNum",
    },
    {
      title: "交卷状态",
      dataIndex: "status",
      render: v => renderEnum(v, submitStatusEnum, zeroOneColor)
    },
    {
      title: "是否生成报告",
      dataIndex: "commentStatus",
      render: v => renderEnum(v, reportStatusEnum, zeroOneColor)
    },
    // {
    //   title: "点评数量",
    //   dataIndex: "commentNum",
    // },
    {
      title: "操作",
      dataIndex: "exercisesId",
      render: (v, record) => {
        return (
          <div>
            <Link style={{ marginRight: 10 }} to={`/ablityTest/list/${v}/finishUserList/userDetail/${record.userId}`}>查看详情</Link>
            {/* <Link to={`/ablityTest/list/${v}/finishUserList/userDetail/${record.userId}/report`}>测评报告</Link> */}
          </div>
        )
      }
    }
  ]

  const searchSchemaList = [
    {
      label: "手机号",
      key: "mobile",
      type: "input",
    },
    {
      label: "交卷状态",
      key: "status",
      type: "select",
      oplist: obj2oplist(submitStatusEnum, Number),
    },
    {
      label: "是否生成报告",
      key: "commentState",
      type: "select",
      oplist: obj2oplist(reportStatusEnum, Number),
    },
  ]

  useEffect(() => {
    setDefaultSearchData({ exerciseId: examId })
  }, [examId])

  return (
    <Card>
      {
        defaultSearchData.exerciseId ? (
          <SearchList
            url="/ability/exercises/user"
            tableColumns={uiSchema}
            outSearchPostData={defaultSearchData}
            searchSchema={searchSchemaList}
            useCache={true}
          />
        ) : null
      }
    </Card>
  )
}