import { Button, Spin, Modal, message } from 'antd'
import { useState, useEffect, useRef } from 'react'
import './index.scss'
import UseTempLateList from '../UseTempLateList'
import PropTypes from 'prop-types'
import Editor from '../Editor'
import { uploadOSS } from '../../utils/utils'
import { post } from '../../common/request'
import RecordComponent from '../RecordAudio'
import P from '../P'

export default function DianPin(props) {
  const answerVO = props.answerVO || {}
  const [showUserModal, setShowUserModal] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [teacherDianPinText, setTeacherDianPinText] = useState("") // 老师的文字点评内容
  const teacherVoiceRef = useRef()

  // 选中点评模板的时候触发
  const selectHandler = (id, option) => {
    setTeacherDianPinText((teacherDianPinText || "") + option.content)
  }

  const renderModalTitle = () => {
    return (
      <div className="row23029032h">
        <div>学员面试点评</div>
        <div style={{ marginRight: 30 }}>
          <UseTempLateList onChange={selectHandler} />
        </div>
      </div>
    )
  }

  const verifyData = () => {
    if (!teacherDianPinText) {
      message.warn('文字点评不能为空');
      return true;
    }
    return false;
  };

  const modifyUser = async () => {
    let running = teacherVoiceRef.current.state.running;
    if (running) return message.warn('请完成录音');
    if (verifyData()) return;

    const { cLength, cSize, commentUrl, questionId, id, remark, userId } = answerVO

    let fileName = `interview/teacher/audio/${questionId}/${userId}/${Date.now()}.m4a`;
    let fileAudio = teacherVoiceRef.current.state.blob;

    let params = {
      comment: teacherDianPinText,
      questionId,
      id,
      remark,
      userId,
    }

    // 应用老语音数据
    cLength ? params.cLength = cLength : '';
    cSize ? params.cSize = cSize : '';
    commentUrl ? params.commentUrl = commentUrl.split('aliyuncs.com/')[1] : '';

    setLoadingModal(true)
    if (fileAudio !== null) {
      // 如果有新语音上传，则覆盖老数据，使用新语音
      let cLength = teacherVoiceRef.current.state.duration;
      let cSize = teacherVoiceRef.current.state.fileSize;
      params.cLength = cLength;
      params.cSize = cSize;
      params.commentUrl = fileName;
      await uploadOSS(fileName, fileAudio, 'jd-user');
    }

    return post("/gk/ops/interview/interviewFeedback", params).then(() => {
      message.success("操作成功")
      props.reloadHandler && props.reloadHandler()
      setShowUserModal(false)
    }).finally(() => {
      setLoadingModal(false)
    })
  }

  const handleCancel = () => {
    setShowUserModal(false)
    setLoadingModal(false)
    setTeacherDianPinText("")
  }

  const clickDianPin = () => {
    setShowUserModal(true)
  }

  // 当老师的问题点评发生变化时，同步到state里面
  useEffect(() => {
    setTeacherDianPinText(answerVO?.["comment"] || "")
  }, [answerVO?.["comment"]])

  return (
    <div>
      <P code="interview:stu:feedback" onClick={clickDianPin} type="primary">点评</P>
      <Modal
        title={renderModalTitle()}
        visible={showUserModal}
        onOk={modifyUser}
        onCancel={handleCancel}
        width={1200}
        okText='提交'
        destroyOnClose={true}
        confirmLoading={loadingModal}
      >
        <div className="dianpinctonaienr">
          <Spin tip="点评上传中，请稍等..." spinning={loadingModal}>
            <div className="partcls">
              <div style={{ marginBottom: 8 }}>学员做答</div>
              {
                answerVO?.remark &&
                  <div className="studentRemark">{answerVO?.remark || ''}</div>
              }
            </div>

            <div className="partcls">
              {
                answerVO?.voiceUrl && <div className="flexc">
                  <label>学员语音：</label>
                  <audio controls="controls" src={answerVO?.voiceUrl}>您的浏览器不支持 audio 标签</audio>
                </div>
              }
            </div>

            <div className="partcls">
              {/** 老师的文字点评 */}
              <div style={{marginTop: '10px', marginBottom: 8}}>文字点评：</div>
              <Editor
                content={teacherDianPinText}
                onChange={value => setTeacherDianPinText(value)}
              />
            </div>

            {/** 老师之前的语音点评 */}
            <div className="partcls">
              {
                answerVO?.commentUrl && <div style="flexc">
                  <div>近一次老师语音点评：</div>
                  <audio controls="controls" src={answerVO?.commentUrl}>您的浏览器不支持 audio 标签</audio>
                </div>
              }
            </div>

            <div className="partcls">
              <div style={{marginTop: '10px'}}>语音点评(可选)：</div>
              <RecordComponent ref={teacherVoiceRef}></RecordComponent>
            </div>
          </Spin>
        </div>
      </Modal>
    </div>
  )
}

DianPin.PropTypes = {
  answerVO: PropTypes.any,
}