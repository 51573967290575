/*
 * 图片压缩
 * @param {object} file :图片文件信息
 * @param {string} width :宽
 * @param {string} height :高
 */
export const compression = (file, width, height) => {
  if (file && file.size < 1024 * 100 * 5) {
    // 小于500kb的暂不压缩
    return Promise.resolve({
      file,
    });
  }

  return new Promise((resolve) => {
    const reader = new FileReader(); // 创建 FileReader
    reader.onload = ({ target: { result: src } }) => {
      const image = new Image(); // 创建 img 元素
      image.onload = async () => {
        const canvas = document.createElement("canvas"); // 创建 canvas 元素
        canvas.width = width || image.width;
        canvas.height = height || image.height;
        let context = canvas.getContext("2d");
        // 在canvas绘制前填充白色背景
        // context.fillStyle = "#fff"
        // context.fillRect(0, 0, width || image.width, height || image.height)
        context.drawImage(
          image,
          0,
          0,
          width || image.width,
          height || image.height
        ); // 绘制 canvas
        const canvasURL = canvas.toDataURL("image/jpeg", 0.8);
        const buffer = atob(canvasURL.split(",")[1]);
        let length = buffer.length;
        const bufferArray = new Uint8Array(new ArrayBuffer(length));
        while (length--) {
          bufferArray[length] = buffer.charCodeAt(length);
        }
        const miniFile = new File([bufferArray], file.name, {
          type: "image/jpeg",
        });
        miniFile.uid = 0;
        resolve({
          file: miniFile,
          origin: file,
          beforeSrc: src,
          afterSrc: canvasURL,
          beforeKB: Number((file.size / 1024).toFixed(2)),
          afterKB: Number((miniFile.size / 1024).toFixed(2)),
        });
      };
      image.src = src;
    };
    reader.readAsDataURL(file);
  });
};