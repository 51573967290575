import { Card, Form, Button, Row, Col, message } from "antd";
import FormList from "../../../components/FormItem";
import { obj2oplist, articleStatusEnum } from "../../../common/consts";
import { post, get } from "../../../common/request";
import { useState, useEffect } from "react";
import { useGet } from "../../../hooks/useGet";
import moment from "moment";

export default function(props) {
  const id = props.match?.params?.id

  const [formInstance] = Form.useForm()
  const [detail, setDetail] = useState({})
  const [loadingDetail, setLoadingDetail] = useState(false)
  const enums = useGet("/interview/condition")
  const quesTypeList = enums?.categories || []

  const returnCardTitle = () => {
    if (id != undefined) {
      return "修改文章"
    }
    return "新增文章"
  }

  const formListConfig = [
    {
      label: "标题",
      key: "title",
      type: "input",
      required: true,
      maxLength: 30,
    },
    {
      label: "作者",
      key: "author",
      type: "input",
      required: true,
      maxLength: 8,
    },
    {
      label: "简介",
      key: "intro",
      type: "input",
      required: true,
    },
    {
      label: "面试题型",
      key: "typeId",
      type: "select",
      oplist: quesTypeList,
      opk: "id",
      opv: "name",
      required: true,
    },
    {
      label: "发布时间",
      key: "publishTime",
      type: "datePicker",
      required: true,
    },
    {
      label: "状态",
      key: "status",
      type: "radio",
      required: true,
      oplist: obj2oplist(articleStatusEnum, Number),
    },
    {
      label: "文章内容",
      key: "content",
      type: "richtext",
      required: true,
      height: 500,
    }
  ]

  const saveHandler = async () => {
    const formValues = await formInstance.validateFields()
    const postData = {
      ...(detail || {}),
      ...formValues,
    }
    try {
      postData.publishTime = moment(postData.publishTime).format("YYYY-MM-DD HH:mm:ss")
    } catch (err) {}
    return post("/system/article/saveOrUpdate", postData).then(res => {
      message.success("操作成功")
      props.history.goBack()
    })
  }

  useEffect(() => {
    if (!id) {
      return
    }
    setLoadingDetail(true)
    get(`/system/article/${id}`).then(res => {
      const detailObj = res || {}
      if (detailObj.publishTime) {
        detailObj.publishTime = moment(detailObj.publishTime)
      }
      setDetail(detailObj)
      setLoadingDetail(false)
      formInstance.setFieldsValue(detailObj)
    })
  }, [id])

  return (
    <Card title={returnCardTitle()} loading={loadingDetail}>
      <Form form={formInstance} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
        <FormList
          span={24}
          list={formListConfig}
        />
      </Form>
      <Row>
        <Col offset={2}>
          <Button onClick={saveHandler} type="primary">保存</Button>
        </Col>
      </Row>
    </Card>
  )
}