import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchQuestionCategories } from "../ModuleSetting/actions";
import { fetchQuestionAreas, fetchQuestionYears } from '../QuestionList/actions';
import { Typography, Spin, Breadcrumb, Radio, message, Select, Tooltip, Button, Card, Space, Input, Checkbox } from 'antd';
import {
    add_option,
    receive_question_info,
    remove_option,
    request_question_info,
    update_category,
    update_multiple_question_answer,
    update_question_answer_desc,
    update_question_analysis,
    update_question_materials_type,
    update_question_point,
    update_question_source,
    update_question_knowledge,
    update_question_selection,
    update_question_title,
    update_question_type,
    updateQuestionVideo,
    update_single_question_answer,
    updateQuestion,
    fetchMaterialQuestion,
    update_question_tag_type,
    fetchEmpty,
    update_url,
    update_signedVideoUrl,
    update_years_type,
    update_province_type
} from "./actions";
import Editor from "../../components/Editor/index";

import VideoPicker from '../../components/VideoPicker';
import { fetchResource, fetchTag } from "../EssayCategory/actions"; // 引入从服务端获取tag的action
import { checkPermission } from '../../utils/utils';
import apiRequest from '../../common/request'
import { OSS_PUB_GK_COURSE } from '../../common/consts';
import { getAvailableVideoUrl } from '../../components/VideoPicker/action';

const { Option } = Select;
const { Paragraph } = Typography;

const MIN_ANSWER = 2;
const MAX_ANSWER = 10;
const ALPHA = ['A', 'B', 'C', 'D', 'E', "F", 'G', "H", 'I', 'J', 'K'];

class QuestionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionTag: '',
            categoryId: '',

            fstCategoryId: '-1',
            secCategoryId: '-1',
            trdCategoryId: '-1',
            hasSecondCate: false,
            hasThirdCate: false,
            secondCategories: [],
            thirdCategories: [],
            showVideoModal: false,
            showNextAndPrevBtn: false,
            size: null,
        };
    }

    componentDidMount() {
        this.checkShowNextAndPrev();
        this.props.dispatch(fetchQuestionCategories());
        this.props.dispatch(fetchQuestionYears());
        this.props.dispatch(fetchQuestionAreas());
        this.props.dispatch(fetchTag());
        if (this.props.match.params.questionId) {
            this.fetchQuestion(this.props.match.params.questionId);
        } else {
            this.props.dispatch(fetchEmpty());
        }
    }

    fetchQuestion = (questionId) => {
        this.props.dispatch(request_question_info());
        apiRequest(`/gk/ops/question/details/${questionId}`, { credentials: 'include' })
            .then(json => {
                // 如果是材料题，就fetch它的材料过来              
                if (!json.obj.options) {
                    json.obj.options = [
                        { key: '', value: '' },
                        { key: '', value: '' },
                        { key: '', value: '' },
                        { key: '', value: '' }
                    ]
                }
                if (json.obj.materialsId)
                    this.props.dispatch(fetchMaterialQuestion(json.obj.materialsId));
                this.props.dispatch(receive_question_info(json.obj));
                if (json.obj.categoryTree && json.obj.categoryTree.length) {
                    json.obj.categoryTree.reverse();
                }
                this.selectFirstCate(json.obj.categoryTree && json.obj.categoryTree[0] ? json.obj.categoryTree[0].id.toString() : '-1');
                if (json.obj.categoryTree && json.obj.categoryTree[1]) {
                    this.selectSecondCate(json.obj.categoryTree[1].id.toString());
                }
                if (json.obj.categoryTree && json.obj.categoryTree[2]) {
                    this.selectThirdCate(json.obj.categoryTree[2].id.toString());
                }
                if (json.obj.questionTag) {
                    // 每道试题都只对应着一个tag
                    this.props.dispatch(update_question_tag_type(json.obj.questionTag));
                }
                if (json.obj.area) {
                    this.getProvince(json.obj.area);
                }
                if (json.obj.year) {
                    this.getYear(json.obj.year);
                }
            })
            .catch(error => console.log(error));
    };

    // 获取省份列表
    getProvince = value => {
        this.props.dispatch(update_province_type(value));
    }

    // 获取年份列表
    getYear = value => {
        this.props.dispatch(update_years_type(value));
    }

    selectFirstCate = async (val) => {
        await this.setState({ fstCategoryId: val, categoryId: val, secondCategories: [], thirdCategories: [], secCategoryId: '-1', trdCategoryId: '-1' });
        this.props.categories.map(item => {
            item.id === Number(val) && this.setState({ secondCategories: item.subset });
        });
        this.props.dispatch(update_category(val));
    }

    selectSecondCate = async (val) => {
        let categoryId = (val=='-1' ? this.state.fstCategoryId : val);
        await this.setState({ secCategoryId: val, categoryId, thirdCategories: [], trdCategoryId: '-1' });
        this.state.secondCategories.map(item => (
            item.id === Number(val) && this.setState({ thirdCategories: item.subset })
        ));
        this.props.dispatch(update_category(val));
    }

    selectThirdCate = async (val) => {
        let categoryId = (val=='-1' ? this.state.secCategoryId : val);
        await this.setState({ trdCategoryId: val, categoryId });
        this.props.dispatch(update_category(val));
    }

    closeVideoModal = () => {
        this.setState({ showVideoModal: false });
    };

    videoPickerFeedBack = async (v) => {
        let canPlayUrl = await getAvailableVideoUrl(v);
        this.setState({ showVideoModal: false });
        this.props.dispatch(update_url(v));
        this.props.dispatch(update_signedVideoUrl(canPlayUrl));
    };

    videoPickerSize = value => {
        this.setState({ size: value })
    };

    uploadFile = () => {
        let file = this.refs.upload.files[0];
        if (file) {
            this.props.dispatch(updateQuestionVideo(file, this.props.info));
        } else {
            message.warning('请先选择文件');
        }
    };

    /** bad design: 寻找原创 */
    findSinture = () => {
        return this.props.tags.find(obj => obj.desc.includes('焦点'));
    }

    /** 判断是否需要显示省份和年份；只有当questionTag不是原创的时候才有必要显示 */
    showAreaAndYear = () => {
        if (!this.props.tags || !this.props.tags.length) return false;
        let sintureObj = this.findSinture();
        return this.props.info.questionTag != sintureObj.value;
    }

    modifyQuestion = () => {
        if ((!this.props.info.content) || this.props.info.content.trim() === "") {
            message.warning('请输入问题');
            return;
        }

        if (!this.props.info.options.every(v => v.value.trim() !== "")) {
            message.warning('请输入候选项内容');
            return;
        }

        // 答案数组
        if (typeof this.props.info.answer === "string") {
            if (!this.props.info.answer.split(',').filter(i => i.length > 0).length) {
                message.warning('请选择正确答案');
                return;
            }
        } else {
            if (!this.props.info.answer.filter(i => i.length > 0).length) {
                message.warning('请选择正确答案');
                return;
            }
        }

        if (this.props.info.quesExplain.trim() === "") {
            message.warning('请输入试题解析');
            return;
        }

        if (this.props.info.categoryId == -1) {
            message.warning('请选择分类');
            return;
        }

        if (this.props.info.questionTag == "") {
            message.warning('请选择标签');
            return;
        }

        if (!this.props.info.source) {
            message.warning('请输入试题来源');
            return;
        }

        let showAY = this.showAreaAndYear();

        if (showAY) {

            if (!this.props.info.area || !this.props.info.area.length) {
                message.warning('请选择省份');
                return;
            }

            if (!this.props.info.year || !this.props.info.year.length) {
                message.warning('请选择年份');
                return;
            }

        }
        this.props.dispatch(updateQuestion(this.props.info, showAY));
    };

    deleteVideo = () => {
        this.props.dispatch(update_signedVideoUrl(null));
        this.props.dispatch(update_url(null));
    };

    /** 检查是否有必要出现底部的功能区域 */
    checkShowNextAndPrev = () => {
        let questionsIds = JSON.parse(localStorage.getItem('questionsIds')) || [];
        this.questionsIds = questionsIds;
        this.questionId = this.props.match.params.questionId;
        let showNextAndPrevBtn = false;
        if (Object.prototype.toString.call(this.questionsIds) !== '[object Array]') showNextAndPrevBtn = false;
        else showNextAndPrevBtn = this.questionsIds.includes(+this.questionId);
        this.setState({ showNextAndPrevBtn });
    }

    /** 点击上一题 */
    prevStudent = () => {
        let currentIndex = this.findCurrentStudentIdx();
        if (currentIndex <= 0) return;
        let nextStudentId = this.findPrevOrNextId(currentIndex, true);
        this.jump2NextPrevStudent(nextStudentId);
    }

    /** 点击下一题 */
    nextStudent = () => {
        let currentIndex = this.findCurrentStudentIdx();
        if (currentIndex >= this.questionsIds.length - 1) return;
        let nextStudentId = this.findPrevOrNextId(currentIndex);
        this.jump2NextPrevStudent(nextStudentId);
    }

    /** 返回当前试题的ID */
    findCurrentStudentIdx = () => {
        if (Object.prototype.toString.call(this.questionsIds) != '[object Array]') return;
        this.questionId = this.props.match.params.questionId;
        return this.questionsIds.indexOf(+this.questionId);
    }

    /** 返回上下题的ID */
    findPrevOrNextId = (currentIndex, prev) => {
        if (prev) return this.questionsIds[currentIndex - 1];
        else return this.questionsIds[currentIndex + 1];
    }

    /** 页面跳转到上下题 */
    jump2NextPrevStudent = questionsId => {
        let url = `/question/${questionsId}`;
        this.props.history.replace(url);
        this.fetchQuestion(questionsId);
    }

    render() {
        const { info, categories, isUpdating, isFetching } = {...this.props};
        let { secondCategories, thirdCategories, fstCategoryId, secCategoryId, trdCategoryId } = this.state;
        let currentIndex = this.findCurrentStudentIdx();                                  // 当前题目的索引位置
        let prevNums = currentIndex;                                                      // 当前题目前面还有多少位学员
        let nextNums = this.questionsIds ? this.questionsIds.length - currentIndex - 1 : 0;   // 当前题目后面还有多少位学员

        return (
            <Spin spinning={isUpdating || isFetching}>
            <div className="commonFlexLR">
                <div className="commonLeft">
                    <Breadcrumb>
                        <Breadcrumb.Item>公务员试题</Breadcrumb.Item>
                        <Breadcrumb.Item>行测试题详情</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="commonSearchBox">
                        <div className="itemSearchBox">
                            <label>一级分类</label>
                            <Select style={{ width: 150 }} dropdownMatchSelectWidth={false} value={fstCategoryId} onChange={value => { this.selectFirstCate(value) }}>
                                <Option value="-1">全部</Option>
                                {(categories || []).map((category, idx) => (
                                    <Option value={category.id.toString()} key={idx}>{category.name}</Option>
                                ))}
                            </Select>
                        </div>
                        <div className="itemSearchBox">
                            <label>二级分类</label>
                            <Select style={{ width: 150 }} dropdownMatchSelectWidth={false} value={secCategoryId} disabled={!secondCategories.length} onChange={value => { this.selectSecondCate(value) }}>
                                <Option value="-1">全部</Option>
                                {secondCategories.map((category, idx) => (
                                    <Option value={category.id.toString()} key={idx}>{category.name}</Option>
                                ))}
                            </Select>
                        </div>
                        <div className="itemSearchBox">
                            <label>三级分类</label>
                            <Select style={{ width: 150 }} dropdownMatchSelectWidth={false} value={trdCategoryId} disabled={!thirdCategories.length} onChange={value => { this.selectThirdCate(value) }}>
                                <Option value="-1">全部</Option>
                                {thirdCategories.map((category, idx) => (
                                    <Option value={category.id.toString()} key={idx}>{category.name}</Option>
                                ))}
                            </Select>
                        </div>
                        <div className="itemSearchBox">
                            <label>标签</label>
                            <Select style={{ width: 150 }} value={info.questionTag || 2} onChange={value => this.props.dispatch(update_question_tag_type(value))}>
                                <Option value="">请选择</Option>
                                {this.props.tags.map((tag, idx) => {
                                    return <Option key={idx} value={tag.value}>{tag.desc}</Option>
                                })}
                            </Select>
                        </div>
                        <div className="itemSearchBox">
                            <label>题型</label>
                            <Select style={{ width: 150 }} value={String(info.quesType) || ''} onChange={value => this.props.dispatch(update_question_type(value))}>
                                <Option value={'0'}>单选题</Option>
                                <Option value={'1'}>多选题</Option>
                                <Option value={'5'}>不定项</Option>
                            </Select>
                        </div>
                        {this.showAreaAndYear() && <div className="itemSearchBox">
                            <label>省份</label>
                            <Select
                                placeholder=" 请选择省份（可多选）"
                                style={{ width: '400px' }}
                                mode="multiple"
                                dropdownMatchSelectWidth={false}
                                value={this.props.info.area}
                                onChange={(value) => { this.getProvince(value) }}
                            >
                                {this.props.areas.map((province, idx) => {
                                    return <Option key={idx} value={province}>{province}</Option>
                                })}
                            </Select></div>}
                        {this.showAreaAndYear() && <div className="itemSearchBox"><label>年份</label>
                            <Select
                                placeholder=" 请选择年份（可多选）"
                                style={{ width: '400px'}}
                                mode="multiple"
                                dropdownMatchSelectWidth={false}
                                onChange={(value) => { this.getYear(value) }}
                                value={this.props.info.year}
                            >
                                {this.props.years && this.props.years.map((year, idx) => {
                                    return <Option key={idx} value={year}>{year}年</Option>
                                })}
                            </Select>
                        </div>}
                    </div>
                    <Card size="small" title="来源">
                        <Input value={info.source} onChange={e => this.props.dispatch(update_question_source(e.target.value))} style={{ width: "100%" }} placeholder="来源" />
                    </Card>
                    {
                        info.materialsId && !this.props.isFetchingMaterial &&
                        <Card size="small" title="资料内容">
                            <Link to={`/materialQuestion/xc/${info.materialsId}`}>[跳转编辑]</Link>
                            <div dangerouslySetInnerHTML={{ __html: this.props.materialsInfo.content || '' }}></div>
                        </Card>
                    }
                    <Card size="small" title="问题">
                        <Editor
                            content={info.content || ''}
                            onChange={value => this.props.dispatch(update_question_title(value))}
                        />
                    </Card>

                    {info.options && info.options.length && info.options.map((item, i) => (
                        <Card size="small" key={i} title={`答案${ALPHA[i]}`}>
                            <Editor
                                content={info.options[i]['value'] || ''}
                                onChange={((i) => {
                                    return (value) => {
                                        this.props.dispatch(update_question_selection(i, ALPHA[i], value))
                                    }
                                })(i)}
                            />
                        </Card>
                    ))}
                    <Button style={{ width: '45%', marginRight: '10%' }} danger
                        onClick={() => {
                            if (this.props.info.options.length - 1 < MIN_ANSWER) {
                                message.warning(`最少需有${MIN_ANSWER}个候选项`);
                            } else {
                                this.props.dispatch(remove_option())
                            }
                        }}
                    >删除选项</Button>
                    <Button style={{ width: '45%', marginBottom: '20px' }} type="primary"
                        onClick={() => {
                            if (this.props.info.options.length + 1 > MAX_ANSWER) {
                                message.warning(`最多只能有${MAX_ANSWER}个候选项`);
                            } else {
                                this.props.dispatch(add_option())
                            }
                        }}
                    >新增选项</Button>

                    {/* info.quesType == '0'是单选题，1，5是多选和不定项 */}
                    <Card size="small" title="正确答案">
                        {info.quesType == '0' ?
                            <Radio.Group value={info.answer[0]} onChange={e => this.props.dispatch(update_single_question_answer(e.target.value))}>
                                {(info.options || []).map((v, i) => <Radio key={i} value={ALPHA[i]}>{ALPHA[i]}</Radio>)}
                            </Radio.Group>
                            :
                            <Checkbox.Group value={info.answer} style={{ width: '100%' }} onChange={value => this.props.dispatch(update_multiple_question_answer(value.map(v => v)))}>
                                {(info.options || []).map((v, i) => <Checkbox key={i} value={ALPHA[i]}>{ALPHA[i]}</Checkbox>)}
                            </Checkbox.Group>
                        }
                    </Card>

                    <Card size="small" title="题目解析">
                        <Editor
                            content={info.quesExplain || ''}
                            onChange={value => this.props.dispatch(update_question_answer_desc(value))}
                        />
                    </Card>
                    <Card size="small" title="疑难解答">
                        <Editor
                            content={info.issue || ''}
                            onChange={value => this.props.dispatch(update_question_analysis(value))}
                        />
                    </Card>
                </div>

                <div className="commonsubmit">
                    <div className="cardR" style={{ height: '88vh', overflow: 'auto' }}>
                        {this.state.showNextAndPrevBtn && <div style={{ marginBottom: '10px' }}>
                            <Tooltip title={`前面还有${prevNums}道题`}>
                                <Button disabled={this.questionsIds && currentIndex <= 0} onClick={this.prevStudent} type="primary" className="mr30">上一题</Button>
                            </Tooltip>
                            <Tooltip title={`后面还有${nextNums}道题`}>
                                <Button disabled={this.questionsIds && currentIndex >= this.questionsIds.length - 1} onClick={this.nextStudent} type="primary">下一题</Button>
                            </Tooltip>
                        </div>}
                        <Card size="small" title="视频">
                            <Space direction="vertical">
                                {
                                    this.props.isVideoUpdating && <Spin tip="上传中..."></Spin>
                                }
                                {
                                    info.signedVideoUrl ?
                                        <video style={{ width: "100%" }} controls src={info.signedVideoUrl} />
                                        :
                                        info.videoUrl ?
                                            <video style={{ width: "100%" }} controls src={info.videoUrl} />
                                            :
                                            ''
                                }
                                {info.videoUrl && <Paragraph style={{fontSize: '12px'}} copyable={{ text: info.videoUrl }}>{info.videoUrl}</Paragraph>}
                                {checkPermission('sys:question:edit') && <div>
                                    <input type="file" ref="upload" style={{ margin: '10px 0' }} />
                                    {info.videoUrl && <Button style={{ marginBottom: 10 }} type="danger" onClick={this.deleteVideo}>删除视频</Button>}
                                    <br />
                                    <Button type="primary" onClick={this.uploadFile}>上传本地文件</Button>
                                    <p> 上传完记得点更新</p>
                                    <Button type="primary" onClick={() => this.setState({ showVideoModal: true })}>从视频库选择</Button>
                                    <p> 选择完记得点更新</p>
                                </div>}
                            </Space>
                        </Card>
                        {checkPermission('sys:question:edit') && <Card size="small" title="更新">
                            <Button disabled={isUpdating} type="primary" onClick={this.modifyQuestion}>更新</Button>
                            <p style={{ marginTop: '10px' }}><span style={{ color: 'red' }}>注意:</span>用户已做试题记录，pdf生成试题相关内容，无法通过修改试题后进行变动，请谨慎使用试题修改！！！</p>
                        </Card>}
                    </div>
                </div>
                {this.state.showVideoModal ?
                    <VideoPicker
                        pubName={OSS_PUB_GK_COURSE}
                        videoKey={"key"}
                        showModal={this.state.showVideoModal}
                        closeModal={this.closeVideoModal}
                        feedBack={this.videoPickerFeedBack}
                        getSize={this.videoPickerSize}
                    /> : null}
            </div>
            </Spin>
        )
    }

}

function mapStatetoProps(state) {
    const {
        isFetching,
        isUpdating,
        isUpdated,
        isVideoUpdating,
        info,
        materialsInfo,
        isFetchingMaterial,
        progress
    } = state.selectedQuestion;

    const {
        items: categories
    } = state.questionCategories;

    const {
        resource,
        tags
    } = state.essayCategory;

    const {
        items: questionList,
        years,
        areas
    } = state.questions;

    return {
        questionList,
        years,
        areas,
        progress,
        isFetching,
        isFetchingMaterial,
        isUpdating,
        isUpdated,
        isVideoUpdating,
        info,
        categories,
        materialsInfo,
        tags
    };
}

export default connect(mapStatetoProps)(QuestionInfo);
