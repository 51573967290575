import PropTypes from "prop-types"
import Editor from "../../../Editor/index"

const RichText = props => {
  const changeInputHandler = val => {
    props.onChange(val)
  }

  return (
    <Editor
      onChange={val => changeInputHandler(val)}
      content={props.value}
      disabled={props.disabled}
      height={props.height}
    />
  )
}

RichText.propTypes = {
  disabled: PropTypes.bool, // 是否需要禁止选择
  value: PropTypes.any, // 值
  onChange: PropTypes.func, // 值修改的时候触发
}

export default RichText