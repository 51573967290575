import { checkPermission } from "../../utils/utils";
import PropTypes from "prop-types";
import { Button, Tooltip } from "antd";

const P = ({ code, children, ...otherProps }) => {
  if (!checkPermission(code)) {
    return null
    return (
      <Tooltip title="无权限进行此操作">
        <Button {...otherProps} onClick={() => {}}>
          {children}
        </Button>
      </Tooltip>
    );
  }

  return <Button {...otherProps}>{children}</Button>;
};

P.propTypes = {
  code: PropTypes.string, // 权限名
};

export default P;
