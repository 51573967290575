import React from "react";
import { connect } from "react-redux";
import {
    update_question_displaytitle,
    update_question_suitCrowds,
    update_question_materials,
    update_question_source,
    update_question_joinmaterial,
    add_question_materials,
    add_question_question,
    remove_question_materials,
    remove_question_question,
    update_question_method,
    update_question_solution,
    update_question_year,
    update_question_area,
    update_question_quesType,
    update_question_description,
    update_question_tag,
    updateQuestionVideo,
    updateQuestion,
    fetchShenlun,
    fetchEmpty
} from "./actions";
import { fetchZyYearList, fetchShenlunAreas } from '../ZhyList/actions'
import { fetchTag } from "../EssayCategory/actions";
import { fetchZYCategories } from '../ModuleSetting/actions';
import Editor from "../../components/Editor/index";
import { checkPermission } from '../../utils/utils';
import { Select, message, Card, Button, Input } from 'antd';

const { Option } = Select;
const buttonName = ['显示问题', '显示材料'];

class ZhyInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMaterial: true,
        };
    }

    componentDidMount() {
        if (this.props.match.params.zyId) {
            this.props.dispatch(fetchShenlun(this.props.match.params.zyId));
        } else {
            this.props.dispatch(fetchEmpty());
        }
        this.props.dispatch(fetchZYCategories());
        this.props.dispatch(fetchTag());
        this.props.dispatch(fetchZyYearList());
        this.props.dispatch(fetchShenlunAreas());
    }

    uploadFile = () => {
        let file = this.refs.upload.files[0];
        if (file) {
            this.props.dispatch(updateQuestionVideo(file, this.props.info));
        } else {
            message.warning('请上传文件');
        }
    };

    modifyQuestion = () => {
        let verifyObj = this.props.info;
        let blackList = ['title', 'year', 'questionTag', 'materials', 'questions',];
        let tips = { title: '问题标题', year: '年份', source: '来源', questionTag: '标签', materials: '材料', questions: '问题' };
        blackList.map(item => {
            if (verifyObj[item] == '') {
                message.warning(`${tips[item]}不能为空`);
                return;
            }
        })
        for (let i in this.props.info.materials) {
            let m = this.props.info.materials[i];
            if (m.trim().length === 0) {
                message.warning('材料内容不能为空');
                return;
            }
        }

        for (let i in this.props.info.questions) {
            let q = this.props.info.questions[i];
            if (q.question.trim().length === 0) {
                message.warning('问题描述不能为空');
                return;
            }
        }

        let infoObj = { ...this.props.info };
        this.props.dispatch(updateQuestion(infoObj));
    };

    choseMaterial = (value, index) => {
        this.props.dispatch(update_question_joinmaterial(value, index));
    }

    /** 返回标签的焦点值 */
    generateLabelDefaultValue = () => {
        let info = this.props.info || {};
        let result = '';
        if (info.questionTag) {
            result = info.questionTag.toString();
        } else if (this.props.tags && this.props.tags.length) {
            result = this.findSinture().value + '';
            this.props.dispatch(update_question_tag(result));
        } else {
            result = '-1';
        }
        return result;
    }

    /** bad design: 寻找焦点原创 */
    findSinture = () => {
        return this.props.tags.find(obj => obj.desc.includes('焦点'));
    }

    render() {
        const { info, years } = this.props;
        return (
            <div>
                <h3 style={{ margin: "0 0 20px" }}>{this.props.match.params.zyId ? '综合应用试题详情' : '添加综合应用试题'}</h3>
                {
                    this.props.isFetching &&
                    <h5>加载中……</h5>
                }
                {
                    !this.props.isFetching &&
                    <div className="commonFlexLR">
                        <div className="commonLeft">
                            <div className="commonSearchBox">
                                <div className="itemSearchBox">
                                    <label>标题</label>
                                    <Input style={{ width: 650 }} value={info.title} onChange={e => this.props.dispatch(update_question_displaytitle(e.target.value))} />
                                </div>
                                <div className="itemSearchBox">
                                    <label>适合对象</label>
                                    <Input style={{ width: 150 }} value={info.suitCrowds} onChange={e => this.props.dispatch(update_question_suitCrowds(e.target.value))} />
                                </div>
                                <div className="itemSearchBox">
                                    <label>来源</label>
                                    <Input style={{ width: 150 }} value={info.source} onChange={e => this.props.dispatch(update_question_source(e.target.value))} />
                                </div>
                                <div className="itemSearchBox">
                                    <label>年份</label>
                                    <Select
                                        style={{ width: 150 }}
                                        value={info.year}
                                        dropdownMatchSelectWidth={false}
                                        onChange={value => this.props.dispatch(update_question_year(value))}>
                                        {years.map((year, idx) => {
                                            return <Option key={idx} value={year + ""}>{year}</Option>
                                        })}
                                    </Select>
                                </div>
                                <div className="itemSearchBox">
                                    <label>地区</label>
                                    <Select
                                        style={{ width: 150 }}
                                        value={info.area}
                                        dropdownMatchSelectWidth={false}
                                        onChange={value => this.props.dispatch(update_question_area(value))}>
                                        {this.props.areas && this.props.areas.map((province, idx) => {
                                            return <Option key={idx} value={province + ""}>{province}</Option>
                                        })}
                                    </Select>
                                </div>
                                <div className="itemSearchBox">
                                    <label>标签</label>
                                    <Select
                                        style={{ width: 150 }}
                                        value={info.questionTag}
                                        dropdownMatchSelectWidth={false}
                                        onChange={value => this.props.dispatch(update_question_tag(value))}>
                                        <Option value={-1}>请选择</Option>
                                        {this.props.tags && this.props.tags.map((category, idx) => {
                                            return <Option key={idx} value={category.value}>{category.desc}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>

                            <div style={{ display: 'flex', margin: '10px', 'justifyContent': 'center' }}>
                                <Button type="primary" onClick={() => this.setState({ showMaterial: !this.state.showMaterial })}>{this.state.showMaterial ? buttonName[0] : buttonName[1]}</Button>
                            </div>

                            {/** 材料显示区域 */}

                            {
                                this.state.showMaterial &&
                                <div>
                                    <h3>材料</h3>
                                    {
                                        info.materials && info.materials.map((material, index) => {
                                            return <div key={this.props.mId + '' + index}>
                                                <h4 style={{ margin: '10px 0' }}>材料{index + 1}</h4>
                                                <Editor
                                                    content={material}
                                                    onChange={value => this.props.dispatch(update_question_materials(value, index))}
                                                />
                                                <br />
                                                <Button danger onClick={() => this.props.dispatch(remove_question_materials(index))}>删除材料</Button>
                                            </div>
                                        })
                                    }
                                    <br />
                                    <Button type="primary" onClick={() => this.props.dispatch(add_question_materials())}>添加一个新材料</Button>
                                </div>
                            }

                            {/** 问题显示区域 */}

                            {
                                (!this.state.showMaterial) && info.questions.map((item, index) => {
                                    return (
                                        <Card title={"问题" + (index + 1)} key={this.props.qId + '' + index} size="small">
                                            <div>
                                                <label>题型分类</label>
                                                <Select
                                                    style={{ width: 150 }}
                                                    value={item.categoryId ? item.categoryId + "" : "-1"}
                                                    dropdownMatchSelectWidth={false}
                                                    onChange={value => this.props.dispatch(update_question_quesType(value, index))}>
                                                    <Option value="-1">请选择</Option>
                                                    {this.props.zyCategory && this.props.zyCategory.map((category, idx) => {
                                                        return <Option key={idx} value={category.id + ''}>{category.name}</Option>
                                                    })}
                                                </Select>

                                            </div>
                                            <br />
                                            <div>
                                                <label>材料序号</label>
                                                <Select
                                                    mode="multiple"
                                                    style={{ width: '400px' }}
                                                    placeholder="选择一个材料号"
                                                    value={item.materials}
                                                    onChange={value => this.choseMaterial(value, index)}
                                                >
                                                    {
                                                        info.materials && info.materials.map((item, mIndex) => (
                                                            <Option value={mIndex + 1} key={mIndex}>{mIndex + 1}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                            <br />
                                            <div>
                                                <div>问题描述</div>
                                                <Editor
                                                    content={item.question}
                                                    onChange={value => this.props.dispatch(update_question_description(value, index))}
                                                />
                                            </div>
                                            <br />
                                            <div>
                                                <div>答题思路</div>
                                                <Editor
                                                    content={item.method}
                                                    onChange={value => this.props.dispatch(update_question_method(value, index))}
                                                />
                                            </div>
                                            <br />
                                            <div>
                                                <div>参考解析</div>
                                                <Editor content={item.solution}
                                                    onChange={value => this.props.dispatch(update_question_solution(value, index))}
                                                />
                                            </div>
                                            <br />
                                            <Button danger onClick={() => this.props.dispatch(remove_question_question(index))}>删除问题</Button>
                                        </Card>
                                    )
                                })
                            }
                            {
                                (!this.state.showMaterial) &&
                                <Button type="primary" onClick={() => this.props.dispatch(add_question_question())}>添加一个新问题</Button>
                            }

                        </div>

                        <div className="commonsubmit">
                            {checkPermission('sys:zy:edit') && <Card className="cardR" size="small" title={this.props.match.params.zyId ? '更新' : '保存'}>
                                <Button type="primary" onClick={this.modifyQuestion}
                                    disabled={this.props.isUpdating}>
                                    {this.props.match.params.zyId ? '更新' : '保存'}
                                </Button>
                            </Card>}
                        </div>
                    </div>
                }
            </div>
        )
    }

}

function mapStatetoProps(state) {
    const {
        isFetching,
        isUpdating,
        isUpdated,
        isVideoUpdating,
        info,
        progress,
        qId,
        mId
    } = state.zhyInfo;

    let { tags } = state.essayCategory;
    let { zyCategory } = state.questionCategories;
    let { areas, years } = state.zhyList;

    return {
        zyCategory,
        progress,
        areas,
        years,
        tags,
        isFetching,
        isUpdating,
        isUpdated,
        isVideoUpdating,
        info,
        qId,
        mId,
    };
}

export default connect(mapStatetoProps)(ZhyInfo);