import { Form, Input, Button, message } from 'antd';
import md5 from 'md5';
import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';

function Login(props) {
    const onFinish = (values) => {
        if (!(/^1[3456789]\d{9}$/.test(values.username))) {
            return message.warning('手机号格式有误');
        }
        login(values.username, values.password);
    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(item => message.error(item.errors[0]));
    };

    const login = async (username, password) => {
        let data = {
            loginName: username,
            password: md5(md5(password))
        }
        let config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }
        let response = await fetch(`/gk/ops/user/login`, config);
        let json = await response.json();
        if(json.status != 1){
            return message.error(json.errorMes || '登陆异常,请联系管理员');
        }
        Cookies.set('accessToken', ('Bearer '+json.obj) || '');
        await getRouters();
        await getInfo();
        props.history.push('/');
        setTimeout(() => {
            window.location.reload();
        }, 100); // 用于登录成功刷新路由表
    };

    const getRouters = async () => {
        let headers = { 'Sin-Access-Token': Cookies.get('accessToken') };
        let response = await fetch(`/gk/ops/user/getRouters`, { headers });
        let json = await response.json();
        localStorage.setItem('routers', JSON.stringify(json.obj));
    }

    const getInfo = async () => {
        let headers = { 'Sin-Access-Token': Cookies.get('accessToken') };
        let response = await fetch(`/gk/ops/user/getInfo`, { method: 'POST', headers });
        let json = await response.json();
        localStorage.setItem('user', JSON.stringify(json.obj));
    }

    return (
        <div className="loginBox">
            <div className="titleLogin">
                <h3 className="title">面霸来了</h3>
            </div>
            <Form
                width='200px'
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >

                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: '请输入手机号!',
                        },
                    ]}
                >
                    <Input placeholder="手机号" />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码!',
                        },
                    ]}
                >
                    <Input.Password placeholder="密码" />
                </Form.Item>

                <Form.Item>
                    <Button block type="primary" htmlType="submit">登录</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login