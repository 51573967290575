import React from "react";
import fetch from "isomorphic-fetch";
import Cookies from "js-cookie";
import { Spin } from "antd";
import { Redirect, Route, BrowserRouter, Switch } from "react-router-dom";
import Login from "./containers/Login";
import Role from "./containers/Role";
import Layouts from "./containers/Layouts";
import NoPage from "./containers/404/index";
import QuestionList from "./containers/QuestionList/index";
import PositionList from "./containers/PositionList";
import ModuleSetting from "./containers/ModuleSetting";
import PositionConfig from "./containers/PositionConfig";
import Index from "./containers/Index/index";
import UploadWord from "./containers/UploadWord";
import QuesCorrection from "./containers/QuesCorrection";
import MaterialQuestionList from "./containers/MaterialQuestionList";
import UserFeedback from "./containers/UserFeedback";
import InfoSystemPush from "./containers/InfoSystemPush";
import InfoSystemAdd from "./containers/InfoSystemAdd";
import InfoPushRelease from "./containers/InfoPushRelease";
import PositionPush from "./containers/PositionPush";
import CommonSenseList from "./containers/CommonSenseList";
import CommonSenseInfo from "./containers/CommonSenseInfo";
import TvList from "./containers/TvList";
import TvInfo from "./containers/TvInfo";
import TvArticleList from "./containers/TvArticleList";
import TvArticleInfo from "./containers/TvArticleInfo";
import DailyExamineList from "./containers/DailyExamineList";
import DailyExamineRelease from "./containers/DailyExamineRelease";
import ConfigUserPageByExcel from "./containers/ConfigUserPageByExcel";
import UserConfigPage from "./containers/UserConfigPage";
import CourseList from "./containers/CourseList";
import ModuleBannerList from "./containers/ModuleBannerList";
import ModuleBannerRelease from "./containers/ModuleBannerRelease";
import BannerList from "./containers/BannerList";
import BannerRelease from "./containers/BannerRelease";
import EssayCategory from "./containers/EssayCategory";
import EssayInfo from "./containers/EssayInfo";
import EssayList from "./containers/EssayList";
import GoodsOrder from "./containers/GoodsOrder";
import Goods from "./containers/Goods";
import GoodsDetail from "./containers/GoodsDetail";
import Order from "./containers/Order";
import OrderDetail from "./containers/OrderDetail";
import InterviewCategory from "./containers/InterviewCategory";
import InterviewList from "./containers/InterviewList";
import QuestionInfo from "./containers/QuestionInfo";
import PositionInfo from "./containers/PositionInfo";
import DataOrder from "./containers/DataOrder";
import InterviewClass from "./containers/InterviewClass";
import ClassList from "./containers/ClassList";
import StudentInfo from "./containers/StudentInfo";
import ContractOrder from "./containers/ContractOrder";
import InquiryDetail from "./containers/InquiryDetail";
import Inquiry from "./containers/Inquiry";
import Batch from "./containers/Batch";
import AddOrModify from "./containers/AddOrModify";
import PositionMenu from "./containers/PositionMenu";
import BatchClass from "./containers/BatchClass";
import InterviewClassExercise from "./containers/InterviewClassExercise";
import InterviewClassTest from "./containers/InterviewClassTest";
import InterviewStudentList from "./containers/InterviewStudentList";
import InterviewStudent from "./containers/InterviewStudent";
import IStudentInfo from "./containers/IStudentInfo";
import InterviewCandidateList from "./containers/InterviewCandidateList";
import InterviewStudentDetail from "./containers/InterviewStudentDetail";
import ClassExercise from "./containers/ClassExercise";
import ClassTestInfo from "./containers/ClassTestInfo";
import ExerciseRank from "./containers/ExerciseRank";
import ExerciseRankStudent from "./containers/ExerciseRankStudent";
import ExamineList from "./containers/ExamineList";
import ExamineDetail from "./containers/ExamineDetail";
import MaterialQuestionInfo from "./containers/MaterialQuestionInfo";
import ClassDetail from "./containers/ClassDetail";
import StudentDetail from "./containers/StudentDetail";
import CourseInfo from "./containers/CourseInfo";
import CourseClassInfo from "./containers/CourseClassInfo";
import CourseClassList from "./containers/CourseClassList";
import VerifyVideo from "./containers/VerifyVideo";
import DailyExamineInfo from "./containers/DailyExamineInfo";
import ShenlunDetail from "./containers/ShenlunDetail";
import ShenlunStudentDetail from "./containers/ShenlunStudentDetail";
import MaterialQuestionRelease from "./containers/MaterialQuestionRelease";
import InterviewInfo from "./containers/InterviewInfo";
import SystemMenu from "./containers/SystemMenu";
import SystemRole from "./containers/SystemRole";
import SystemUser from "./containers/SystemUser";
import SysRoleList from "./containers/SysRoleList";
import UserInfo from "./containers/UserInfo";
import PaperList from "./containers/PaperList";
import PaperDetail from "./containers/PaperDetail";
import InstitutionList from "./containers/InstitutionList";
import InstitutionInfo from "./containers/InstitutionInfo";
import ZhyList from "./containers/ZhyList";
import ZhyInfo from "./containers/ZhyInfo";
import DoList from "./containers/DoList";
import Operate from "./containers/Operate";
import TelSearch from "./containers/TelSearch";
import CommentsTemplateList from "./containers/commentsTemplateModule/list";
import CommentsTemplateForm from "./containers/commentsTemplateModule/form";
import HomeBannerModule from "./containers/HomeBannerModule/index";
import ArticleList from "./containers/ArticleModule/List";
import ArticleForm from "./containers/ArticleModule/Form";
import UserAnswerQuestion from "./containers/UserAnswerQuestion/List";
import UserAnswerQuestionDetail from "./containers/UserAnswerQuestion/Form";
import AblityTestList from "./containers/AbilityTest/list";
import AblityTestForm from "./containers/AbilityTest/form";
import AblityFinishUserList from "./containers/AbilityTest/finishUserList";
import AblityUserAnswerDetail from "./containers/AbilityTest/userAnswerDetail";
import AblityUserReport from "./containers/AbilityTest/userReport";
import RecommendedQuestionList from "./containers/RecommendedQuestion/List";
import RecommendedQuestionForm from "./containers/RecommendedQuestion/Form";
import AppUserList from "./containers/AppUserModule/List/index";

let arrRoute = [
  { path: "telSearch", component: TelSearch },
  { path: "doList", component: DoList },
  { path: "zhyAdd", component: ZhyInfo },
  { path: "zhyInfo/:zyId", component: ZhyInfo },
  { path: "zhyList", component: ZhyList },
  { path: "institutionList", component: InstitutionList },
  { path: "institutionInfo/:type/:id", component: InstitutionInfo },
  { path: "institutionAdd/:type", component: InstitutionInfo },
  { path: "paperDetail/:type/:id", component: PaperDetail },
  { path: "paperList", component: PaperList },
  { path: "paperAdd/:type", component: PaperDetail },
  { path: "dailyExamines", component: DailyExamineList },
  { path: "publishDailyExamine", component: DailyExamineRelease },
  { path: "dailyExamine/:dailyExamineId", component: DailyExamineInfo },
  { path: "publishMaterialQuestion/:type", component: MaterialQuestionRelease },
  { path: "module", component: ModuleSetting },
  { path: "positionConfig", component: PositionConfig },
  { path: "infoPush", component: InfoPushRelease },
  { path: "positionPush", component: PositionPush },
  { path: "infoSystemPush", component: InfoSystemPush },
  { path: "infoSystemAdd", component: InfoSystemAdd },
  {
    path: "materialQuestion/:type/:materialQuestionId",
    component: MaterialQuestionInfo,
  },
  { path: "essayList", component: EssayList },
  { path: "essayRelease", component: EssayInfo },
  { path: "essayInfo/:essayId", component: EssayInfo },
  { path: "essayCategory", component: EssayCategory },
  { path: "classTestInfo/:id/:classType", component: ClassTestInfo },
  { path: "classTestRelease/:classId/:classType", component: ClassTestInfo },
  { path: "classExercise", component: ClassExercise },
  { path: "moduleBanners", component: ModuleBannerList },
  { path: "moduleBannerRelease", component: ModuleBannerRelease },
  { path: "moduleBanner/:bannerId", component: ModuleBannerRelease },
  { path: "banners", component: BannerList },
  { path: "bannerRelease", component: BannerRelease },
  { path: "banner/:bannerId", component: BannerRelease },
  {
    path: "course/:courseId/classRelease/:courseType",
    component: CourseClassInfo,
  },
  {
    path: "course/:courseId/class/:classId/:courseType",
    component: CourseClassInfo,
  },
  { path: "course/:courseId/classes/:courseType", component: CourseClassList },
  { path: "courseRelease", component: CourseInfo },
  { path: "course/:courseId", component: CourseInfo },
  { path: "interviewCategory", component: InterviewCategory },
  { path: "interviewList", component: InterviewList },
  { path: "interviewRelease", component: InterviewInfo },
  { path: "flexibleInterview", component: InterviewList },
  { path: "interviewInfo/:id", component: InterviewInfo },
  { path: "configUserPage", component: UserConfigPage },
  { path: "order", component: Order },
  { path: "orderDetail", component: OrderDetail },
  { path: "courses", component: CourseList },
  { path: "classDetail", component: ClassDetail },
  { path: "tvList", component: TvList },
  { path: "tvRelease", component: TvInfo },
  { path: "tv/:id", component: TvInfo },
  {
    path: "studentDetail/:courseId/:userId/:beginDay/:endDay/:classId/:filterStatus",
    component: StudentDetail,
  },
  { path: "tvArticleList", component: TvArticleList },
  { path: "tvArticleRelease", component: TvArticleInfo },
  { path: "tvarticle/:id", component: TvArticleInfo },
  { path: "exerciseRank", component: ExerciseRank },
  { path: "userFeedback", component: UserFeedback },
  { path: "dataOrder", component: DataOrder },
  { path: "goodsOrder", component: GoodsOrder },
  { path: "goods", component: Goods },
  { path: "iStudentInfo/:id/:year", component: IStudentInfo },
  { path: "iStudentInfoRelease", component: IStudentInfo },
  { path: "interviewClass", component: InterviewClass },
  { path: "interviewStudent", component: InterviewStudent },
  { path: "commonSenseRelease", component: CommonSenseInfo },
  { path: "commonSense/:id", component: CommonSenseInfo },
  { path: "commonSenseList", component: CommonSenseList },
  { path: "examineDetail", component: ExamineDetail },
  { path: "examineList", component: ExamineList },
  { path: "classList", component: ClassList },
  {
    path: "exerciseStudentDetail/:userId/:classId",
    component: ExerciseRankStudent,
  },
  { path: "batch/:current", component: Batch },
  { path: "batchClass", component: BatchClass },
  { path: "inquiryDetail", component: InquiryDetail },
  { path: "addOrModify", component: AddOrModify },
  { path: "positionMenu", component: PositionMenu },
  { path: "inquiry", component: Inquiry },
  { path: "contractOrder", component: ContractOrder },
  { path: "studentInfo", component: StudentInfo },
  { path: "interviewStudentDetail", component: InterviewStudentDetail },
  { path: "interviewCandidateList", component: InterviewCandidateList },
  { path: "interviewStudentList", component: InterviewStudentList },
  { path: "interviewClassTest", component: InterviewClassTest },
  { path: "interviewClassExercise", component: InterviewClassExercise },
  {
    path: "shenlunStudentDetail/:id/:exerciseId/:userExamineId",
    component: ShenlunStudentDetail,
  },
  { path: "shenlunDetail", component: ShenlunDetail },
  { path: "role", component: Role },
  { path: "questions", component: QuestionList },
  { path: "publishQuestion", component: QuestionInfo },
  { path: "positionList", component: PositionList },
  { path: "publishPosition", component: PositionInfo },
  { path: "materialQuestions", component: MaterialQuestionList },
  { path: "question/:questionId", component: QuestionInfo },
  { path: "position/:positionId", component: PositionInfo },
  { path: "uploadWord", component: UploadWord },
  { path: "quesCorrection", component: QuesCorrection },
  { path: "configUserPageByExcel", component: ConfigUserPageByExcel },
  { path: "verifyVideo", component: VerifyVideo },
  { path: "createGoods", component: GoodsDetail },
  { path: "goodsDetail/:id", component: GoodsDetail },
  { path: "systemMenu", component: SystemMenu },
  { path: "systemRole", component: SystemRole },
  { path: "systemUser", component: SystemUser },
  { path: "sysRoleList", component: SysRoleList },
  { path: "operate", component: Operate },
  { path: "commentsTemplateList/detail/:id", component: CommentsTemplateForm },
  { path: "commentsTemplateList/new", component: CommentsTemplateForm },
  { path: "commentsTemplateList", component: CommentsTemplateList },
  { path: "config/homeBanner", component: HomeBannerModule },
  { path: "config/article/detail/:id", component: ArticleForm },
  { path: "config/article/new", component: ArticleForm },
  { path: "config/article", component: ArticleList },
  { path: "userManage/userAnswerQuestion/detail/:id", component: UserAnswerQuestionDetail },
  { path: "userManage/userAnswerQuestion", component: UserAnswerQuestion },
  { path: "ablityTest/list/new", component: AblityTestForm },
  { path: "ablityTest/list/detail/:id", component: AblityTestForm },
  { path: "ablityTest/list/:examId/finishUserList/userDetail/:userId/report", component: AblityUserReport },
  { path: "ablityTest/list/:examId/finishUserList/userDetail/:userId", component: AblityUserAnswerDetail },
  { path: "ablityTest/list/:examId/finishUserList", component: AblityFinishUserList },
  { path: "ablityTest/list", component: AblityTestList },
  { path: "recommendedQuestion/new", component: RecommendedQuestionForm },
  { path: "recommendedQuestion/detail/:id", component: RecommendedQuestionForm },
  { path: "recommendedQuestion", component: RecommendedQuestionList },
  { path: "userManage/appUserList/:userId/report", component: AblityUserReport },
  { path: "userManage/appUserList", component: AppUserList },
];
class RouterList extends React.Component {
  state = {
    loading: true,
    randerList: [],
  };

  /** 刷新获取最新路由信息 */
  getRouters = async () => {
    let headers = { "Sin-Access-Token": Cookies.get("accessToken") };
    let response = await fetch(`/gk/ops/user/getRouters`, { headers });
    let json = await response.json();
    if (json.status != 1) {
      Cookies.remove("accessToken");
      window.location = "/login";
    }
    /* json.obj = [];  // 置空路由
        // 模拟新增路由
        let addRoutes = [
            {
                "alwaysShow": true,
                "children": [
                    {
                        "component": "containers/SystemUser",
                        "hidden": false,
                        "meta": {
                            "icon": "fa-cog",
                            "title": "用户管理"
                        },
                        "path": "systemUser"
                    },
                    {
                        "component": "containers/SystemRole",
                        "hidden": false,
                        "meta": {
                            "icon": "fa-calendar-o",
                            "title": "角色管理"
                        },
                        "path": "systemRole"
                    },
                    {
                        "component": "containers/SystemMenu",
                        "hidden": false,
                        "meta": {
                            "icon": "fa-book",
                            "title": "菜单管理"
                        },
                        "path": "systemMenu"
                    },
                    {
                        "component": "containers/SysRoleList",
                        "hidden": true,
                        "meta": {
                            "title": "角色分配"
                        },
                        "path": "sysRoleList"
                    }
                ],
                "component": "Layout",
                "hidden": false,
                "meta": {
                    "icon": "fa-server",
                    "title": "系统管理"
                },
                "redirect": "noRedirect"
            },
        ]
        json.obj = addRoutes; */
    localStorage.setItem("routers", JSON.stringify(json.obj));
  };

  getInfo = async () => {
    let headers = { "Sin-Access-Token": Cookies.get("accessToken") };
    let response = await fetch(`/gk/ops/user/getInfo`, {
      method: "POST",
      headers,
    });
    let json = await response.json();
    if (json.status != 1) {
      Cookies.remove("accessToken");
      window.location = "/login";
    }
    localStorage.setItem("user", JSON.stringify(json.obj));
  };

  async componentDidMount() {
    localStorage.removeItem("auth"); //剔除老版本权限问题
    Cookies.get("accessToken") && (await this.getRouters());
    Cookies.get("accessToken") && (await this.getInfo());

    let routers = [];
    let routerList = [];

    let r =
      (localStorage.getItem("routers") != "undefined" &&
        JSON.parse(localStorage.getItem("routers"))) ||
      [];

    r.map((item) => {
      item.children && routers.push(...item.children);
      item.children &&
        item.children.map((i) => {
          i.children && routers.push(...i.children);
        });
    });

    arrRoute.map((item) => {
      routers.map((i) => {
        if (item.path == i.path) {
          routerList.push(item);
        }
      });
    });
    this.setState({ randerList: routerList, loading: false });
  }

  render() {
    if (this.state.loading) {
      return <Spin tip="加载中..."></Spin>;
    }
    return (
      <BrowserRouter>
        <Switch>
          <Redirect from="/" to="/index" exact />
          <Route path="/login" component={Login} />
          <Route path="/">
            <Layouts>
              <Switch>
                <Route path="/index" component={Index} />
                {this.state.randerList.map((route, i) => (
                  <Route
                    key={i}
                    path={`/${route.path}`}
                    component={route.component}
                  />
                ))}
                <Route path="/userInfo" component={UserInfo} />
                <Route path="*" component={NoPage} />
              </Switch>
            </Layouts>
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default RouterList;
