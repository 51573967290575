import {
    RECEIVE_UPDATE_QUESTION_RESULT,
    RECEIVE_UPDATE_QUESTION_VIDEO_RESULT,
    REQUEST_UPDATE_QUESTION,
    REQUEST_UPDATE_QUESTION_VIDEO,
    REQUEST_QUESTION_INFO,
    UPDATE_QUESTION_SOURCE,
    UPDATE_QUESTION_DISPLAYTITLE,
    UPDATE_QUESTION_SUITCROWDS,
    REMOVE_QUESTION_MATERIALS,
    ADD_QUESTION_QUESTION,
    REMOVE_QUESTION_QUESTION,
    UPDATE_QUESTION_SOLUTION,
    UPDATE_QUESTION_DESCRIPTION,
    UPDATE_QUESTION_YEAR,
    UPDATE_QUESTION_EXAMINETYPE,
    UPDATE_QUESTION_AREA,
    UPDATE_QUESTION_QUESTYPE,
    UPDATE_VIDEO_PROGRESS,
    RECEIVE_QUESTION_INFO,
    UPDATE_QUESTION_JOINMATERIAL,
    UPDATE_QUESTION_QUESCATE,
    UPDATE_QUESTION_TAG,
    UPDATE_RECRUIT_TYPE,
    UPDATE_SCHOOL,
    SET_KEY_VALUE,
    RECEIVE_INTERVIEW_CONDITION,
    CHANGE_COMMON
} from './actions';

export const NO_ANSWER = [];
const INIT_OPTIONS = [{"key": "", "value": ""}, {"key": "", "value": ""}, {"key": "", "value": ""}, {
    "key": "",
    "value": ""
}];

export function interviewInfo(state = {
    isFetching: true,
    isUpdating: false,
    isUpdated: false,
    isVideoUpdating: false,
    info: {},
    materialsInfo: {},
    isFetchingMaterial: false,
    progress: "0",

    schools: [], // 院校
    recruitTypeSchool: [],  // 多元升学类型与学校二级级联
    categories: [], // 面试问题题型
    tags: [],   // 真题、原创
    years: [],  // 年份
}, action) {
    switch (action.type) {
        case SET_KEY_VALUE:
            return Object.assign({}, state, { [action.key]: action.value });
        case CHANGE_COMMON:
            return Object.assign({}, state, { [action.key]: action.val });
        case UPDATE_VIDEO_PROGRESS:
            return Object.assign({}, state, {
                progress: action.value
            })
        case REQUEST_QUESTION_INFO:
            return Object.assign({}, state, {isFetching: true});

        case RECEIVE_QUESTION_INFO:
            console.log(1,state,action)
            // if (action.question.quesType == 0) {
            //     action.question.answer = [action.question.answer];
            // } else if (action.question.quesType == 1 && typeof action.question.answer === "string") {
            //     action.question.answer = action.question.answer.split(',');
            // }

            let infoData = action.question;

            let _schools = []
            state.recruitTypeSchool?.map(item => {
                if (item.id === Number(infoData.type)) {
                    // _schools = item.subset
                    for (let index = 0; index < item.subset.length; index++) {
                        _schools[index] = {value:item.subset[index].id, label:item.subset[index].name}
                    }
                }
            });
            if (infoData.questions) {
                infoData.questions.map(obj => {
                    let richProperties = [ 'question', 'element', 'method', 'mindmap', 'solution' ];
                    richProperties.map(key => {
                        if (obj[key] == null) { obj[key] = ''; }
                    });
                });
            }
            return Object.assign({}, state, {
                schools: _schools,
                qId: (new Date()).getTime() + '',
                mId: (new Date()).getTime() + '',
                isFetching: false,
                info: infoData
            });
        case REQUEST_UPDATE_QUESTION:
            return Object.assign({}, state, {isUpdating: true});
        case REQUEST_UPDATE_QUESTION_VIDEO:
            return Object.assign({}, state, {isVideoUpdating: true, progress: "0"});
        case RECEIVE_UPDATE_QUESTION_RESULT:
            return Object.assign({}, state, {isUpdating: false, isUpdated: true});
        case RECEIVE_UPDATE_QUESTION_VIDEO_RESULT:
            return Object.assign({}, state, {isVideoUpdating: false, isVideoUpdated: true});
        case UPDATE_QUESTION_DISPLAYTITLE:
        case UPDATE_QUESTION_SUITCROWDS:
        case UPDATE_QUESTION_YEAR:
        case UPDATE_QUESTION_AREA:
        case UPDATE_QUESTION_SOURCE:
        case UPDATE_QUESTION_TAG:
        case UPDATE_SCHOOL:
        case UPDATE_QUESTION_EXAMINETYPE:
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {[action.key]: action.value})
            });
        case RECEIVE_INTERVIEW_CONDITION:
            console.log(2,state,action)
            let _conditionSchools = []
            action.condition.recruitTypeSchool.map(item => {
                if (item.id === Number(state.info?.type)) {
                    // _conditionSchools = item.subset
                    for (let index = 0; index < item.subset.length; index++) {
                        _conditionSchools[index] = {value:item.subset[index].id, label:item.subset[index].name}
                    }
                }
            });
            return Object.assign({}, state, {schools: _conditionSchools, categories: action.condition.categories, recruitTypeSchool: action.condition.recruitTypeSchool, tags: action.condition.tags, years: action.condition.years});
        case UPDATE_RECRUIT_TYPE:
            console.log(3,state,action)
            let _updateSchools = []
            state.recruitTypeSchool.map(item => {
                if (item.id == action.value) {
                    // _updateSchools = item.subset
                    for (let index = 0; index < item.subset.length; index++) {
                        _updateSchools[index] = {value:item.subset[index].id, label:item.subset[index].name}
                    }
                }
            });
            return Object.assign({}, state, { schools: _updateSchools,
                info: Object.assign({}, state.info, {[action.key]: action.value})
            });
        case ADD_QUESTION_QUESTION:
            let newQuestion = state.info.questions;
            newQuestion.push({question:"",material:"",solution:"",categoryId:'',examineId:state.info.id});
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {questions: newQuestion})
            });
        case REMOVE_QUESTION_QUESTION:
            let newQuestion2 = state.info.questions;
            newQuestion2.splice(action.index, 1);
            return Object.assign({}, state, {
                qId: (new Date()).getTime()+'',
                info: Object.assign({}, state.info, {questions: newQuestion2})
            });
        case UPDATE_QUESTION_SOLUTION:
        case UPDATE_QUESTION_DESCRIPTION:
        case UPDATE_QUESTION_QUESTYPE:
        case UPDATE_QUESTION_JOINMATERIAL:
        case UPDATE_QUESTION_QUESCATE:
            let arr = state.info.questions;
            arr[action.index][action.key] = action.value;
            return Object.assign({}, state, {
                info: Object.assign({}, state.info, {questions: arr})
            });
        default:
            return state;
    }
}
